/* eslint-disable no-useless-escape */
import { Select } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/Select'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CalendarInterval } from './CalendarInterval'

import {
	deleteUnnormalizedСostsArchive,
	getTplUnnormalizedСostsArchive,
	getUnnormalizedСostsArchive,
	setT7EditModeArchive,
	uploaderUnnormalizedСostsArchive,
} from '@root-gipro/modules/userArchive/store/actions'
import { CarouselWide } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/CarouselWide'
import { SelectAction } from '@root-gipro/modules/userProjects/components/Project/DropDown'
import { UnNormalizedCosts } from '@root-gipro/modules/userProjects/components/Project/UnNormalizedCosts'
import { UnNormalizedForm20Costs } from '@root-gipro/modules/userProjects/components/Project/UnNormalizedForm20Costs'
import {
	copyProject,
	deleteUnnormalizedСosts,
	deleteUserProject,
	downloadForm20,
	getCheckedErrors,
	getDeflators,
	getT7Data,
	getTplUnnormalizedСosts,
	getUnnormalizedСosts,
	saveUserProject,
	setLocked,
	setOnlyPIR,
	setProjectActual,
	setProjectAdditional,
	setProjectCode,
	setProjectDecreeName,
	setProjectDecreeNumber,
	setProjectFilial,
	setProjectGroupNum,
	setProjectName,
	setProjectPhase,
	setProjectRationale,
	setProjectRegions,
	setProjectValuation,
	setProjectYears,
	setT7EditMode,
	setTechConnect,
	updateDeflators,
	uploaderUnnormalizedСosts,
	setProjectFinancialNeeds,
	changeVersionName,
	AddNewVersion,
	autoAddUnc,
} from '@root-gipro/modules/userProjects/store/actions'

import {
	Box,
	Button,
	Grid,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Popover,
	List,
	ListItem,
	Chip,
} from '@material-ui/core'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { ModalContext } from '@root-gipro/context/modal-context'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import {
	CheckProjectWindow,
	DeleteProjectWindow,
	SelectF20Window,
} from '@root-gipro/modules/userProjects/components/Project/Modal'
import '@root-gipro/modules/userProjects/styles/CheckPlate.scss'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { Input } from '@root-gipro/shared/FormElements'
import store from '@root-gipro/store'
import { IFilial, IRegion } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import { intFormat, prettyCost } from '@root-gipro/utils/helpers/common'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import LabelInput from './LabelInput'
import CheckboxItem from '@root-gipro/shared/CheckboxShared/Checkbox'
import { formatValue } from '@root-gipro/modules/userProjects/utils/formatValue'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'
import { DatePicker } from '@material-ui/pickers'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import InputBox from './InputBox'
import FailurePage from '@root-gipro/shared/FailurePage/FailurePage'
import { requiredFields } from '@root-gipro/modules/userProjects/utils/helpers'
import { fetchPublicUserProjectInfo } from '@root-gipro/store/actions'

interface IVersion {
	userProjectId: number
	name: string
	is_actual: boolean
	unc_sort: string
}

const CheckPlate: FC = () => {
	const dispatch = useDispatch()
	const routeMatch = useRouteMatch()
	const history = useHistory()
	const { id: userProjectId }: { id: any } = useParams()
	const typePageUrl: string = routeMatch.path.split('/')[1]
	const currentState = typePageUrl === 'user-archive' ? 'userArchives' : 'userProjects'
	const { regions, filial, failure } = useSelector((state: IState) => state.public)
	const { originalObj, projectObj, sectionIds, t7EditMode, deflators: deflatorsState } = useSelector(
		(state: IState) => state[currentState]
	)
	const currentYear = new Date().getFullYear()
	const { selectedSectionIds, uncs } = useSelector((state: IState) => state.userProjects)

	const sectionIdsArr: number[] = sectionIds.map(item => Number(item.id))
	const { accessory, setAccessory, accessoryRef, setAnchorEl, anchorEl } = useContext(ModalContext)
	const [defaultRegions, setDefaultRegions] = useState<IRegion[]>([])
	const [defaultFilial, setDefaultFilial] = useState<IFilial | null>(null)
	const [versionName, setVersionName] = useState('')
	const [initFilial, setInitFilial] = useState<IFilial[]>([])
	const [isArchived, setIsArchived] = useState<boolean>(false)
	const [isLocked, setIsLocked] = useState<boolean>(false)
	const [isAuthorProject, setIsAuthorProject] = useState<boolean>(false)
	const [author, setAuthor] = useState<string>('')
	const [newAddedVersionName, setNewAddedVersionName] = useState('')
	const [authorTel, setAuthorTel] = useState<string>('')
	const { isAdmin, id } = store.getState().auth.user
	const [isNewVersion, setIsNewVersion] = useState<boolean>(false)

	const [versions, setVersions] = useState<IVersion[] | []>([])

	const [anchorElTable, setAnchorElTable] = useState<any>(null)

	const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date())
	const [isOpenAddVersionDate, setIsOpenAddVersionDate] = React.useState<boolean>(false)

	const handleDateChange = (date: Date | null) => {
		const dateStr = `${date!.getDate()}.${date!.getMonth() + 1}.${date?.getFullYear()} | `

		const splitValue = newAddedVersionName.split('|')
		if (splitValue.length <= 1) {
			setNewAddedVersionName(`${dateStr}${newAddedVersionName}`)
		} else {
			const strWithoutDate = splitValue.slice(1)
			setNewAddedVersionName(`${dateStr}${strWithoutDate}`)
		}
		setSelectedDate(date)
	}

	const precision = deflatorsState?.precision ? deflatorsState?.precision : 12
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElTable(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorElTable(null)
	}

	const calcDeflators = (year: any) => {
		const unc_sort_year = Number(projectObj.unc_sort) === 2024 ? 2023 : 2018
		const filtredDeflators = deflatorsState?.deflators.filter((item: any) => {
			if (item.year >= unc_sort_year && item.year <= currentYear) {
				return item
			}
		})
		if (filtredDeflators && filtredDeflators?.length > 0) {
			const sum = filtredDeflators.reduce((acc, item) => {
				if (item.value) {
					return (acc * Number(item.value)) / 100
				} else {
					return acc
				}
			}, 1)
			if (projectObj.sumUncCostWithNds) {
				return `${Number(intFormat((sum * projectObj.sumUncCostWithNds) / 10 ** 6, true, precision + 1)).toFixed(
					precision
				)}`
			}
		}
	}

	useEffect(() => {
		const isArchived = typePageUrl.includes('user-archive')
		dispatch(getDeflators())
		setIsArchived(isArchived)
	}, [])

	useEffect(() => {
		setVersionName(projectObj.version)
		setIsAuthorProject(projectObj.userId === id)
		setIsLocked(originalObj.locked)
	}, [projectObj, originalObj])

	useEffect(() => {
		let versionsProject: IVersion[] | [] = []
		if (projectObj.versions) {
			versionsProject = [...projectObj.versions]
		}
		setVersions(versionsProject)
	}, [originalObj])

	useEffect(() => {
		if (projectObj.unc_sort) {
			dispatch(fetchPublicUserProjectInfo(projectObj.unc_sort))
		}
	}, [projectObj.unc_sort])

	const years = useMemo(() => {
		let years = []

		if (projectObj && projectObj.years) {
			years = Object.keys(projectObj.years)

			return years.filter(year => Number(year) >= 2018).map(year => [year, projectObj.years[year]?.offer])
		}
	}, [projectObj])

	const deflators = useMemo(() => {
		let years = []
		if (projectObj && projectObj.years) {
			years = Object.keys(projectObj.years)
			const dataArr = years.map(year => {
				const deflator = projectObj.years[year]?.deflator + ''
				const rln = deflator !== '' ? String(deflator?.split('.').pop()).length : 0
				const formatedDeflator = String(Number(formatValue(deflator, precision + 1)).toFixed(precision))
				return [year, formatedDeflator]
			})
			return dataArr
		}
	}, [projectObj])

	const getCurrentRegions = useMemo(() => {
		try {
			return regions.filter((region: IRegion) => projectObj.regionIds.indexOf(region.id) !== -1)
		} catch (error) {
			console.log(error)
		}
	}, [projectObj])

	const getCurrentRegionsIds = useMemo(() => {
		try {
			return regions
				.filter((region: IRegion) => projectObj.regionIds.indexOf(region.id) !== -1)
				.map(region => Number(region.id))
		} catch (error) {
			console.log(error)
		}
	}, [projectObj])

	const defaultRegionName = 'Москва'
	const defaultRegionId = useMemo(() => {
		return regions
			.filter(region => {
				if (region.searchName === defaultRegionName) {
					return region.id
				}
			})
			.map(i => i.id)
	}, [regions])

	const handlerClickDeletePopup = () => {
		dispatch(deleteUserProject(projectObj.id!, projectObj.code))
		setAnchorEl!(null!)
	}
	const handlerClickDownloadF20Popup1 = () => {
		dispatch(downloadForm20(projectObj.id!, projectObj.code, 'new'))
		setAnchorEl!(null!)
	}
	const handlerClickDownloadF20Popup2 = () => {
		setAnchorEl!(null!)
	}

	const handlerClickSave = () => {
		const { author, ...projectObjData } = projectObj

		const emptyFields: string[] = []

		requiredFields.forEach(({ field, message }) => {
			if (field == 'regionIds') {
				if (!defaultRegions.length) {
					emptyFields.push(message)
				}
			} else {
				if (!projectObjData[field]) {
					emptyFields.push(message)
				}
			}
		})

		if (emptyFields.length > 0) {
			dispatch(
				showNotify({
					type: 'warning',
					message: `Не заполнены следующие обязательные поля: ${emptyFields.join(', ')}`,
				})
			)
			return
		}
		const allUncHasVoltage = uncs && uncs.every(item => item.voltage)

		if (!allUncHasVoltage) {
			dispatch(
				showNotify({
					type: 'warning',
					message: 'Нельзя выполнить расчет, т.к. проект содержит расценки без напряжения',
				})
			)
			return
		}
		const uncSort = [...versions].find((version: IVersion) => version?.unc_sort === projectObj.unc_sort)

		if (!projectObj.id && projectObj?.unc_sort) {
			dispatch(AddNewVersion(projectObj, `УНЦ ${projectObj.unc_sort}`, typePageUrl, projectObj?.unc_sort))
		} else if (projectObj.id && projectObj?.unc_sort && !uncSort) {
			const { id, ...projectObjCopy } = projectObj
			projectObjCopy?.unc_sort &&
				dispatch(AddNewVersion(projectObjCopy, `УНЦ ${projectObjCopy.unc_sort}`, typePageUrl, projectObjCopy?.unc_sort))
		} else if (projectObj.id && projectObj?.unc_sort && uncSort) {
			const clone = Object.assign({}, projectObjData)
			delete clone.id
			delete clone.userId
			dispatch(saveUserProject(clone, false, projectObjData.id))
		}
		if (typePageUrl === 'user-archive') return

		if (projectObj?.unc_sort) {
			if (uncSort) {
				history.push(`/${typePageUrl}/project/${uncSort.userProjectId}/parameters`)
			}
		}
	}

	const handlerToArchive = () => {
		if ((!originalObj.locked && isAdmin) || (!originalObj.locked && !isAdmin && isAuthorProject)) {
			const { author, ...projectObjData } = projectObj
			const clone = Object.assign({}, projectObjData)
			clone.archive = 1
			delete clone.id
			delete clone.userId
			dispatch(saveUserProject(clone, false, projectObjData.id))
			setTimeout(() => history.push(`/user-archive/project/${projectObjData.id}/parameters`), 1100)
		} else if (!originalObj.locked && !isAdmin && !isAuthorProject) {
			dispatch(
				showNotify({
					type: 'warning',
					message: `У вас есть доступ для переноса в архив расчетов, созданных вами. Для переноса расчета в архив обратитесь к автору расчета или администратору`,
				})
			)
		} else if (originalObj.locked) {
			if (isAdmin) {
				dispatch(
					showNotify({
						type: 'warning',
						message: `Нельзя переместить в архив данный расчета, так как он заблокирован. Для переноса сначала разблокируйте расчет`,
					})
				)
			} else {
				dispatch(
					showNotify({
						type: 'warning',
						message: `Нет прав на перемещение расчета. Для переноса расчета в архив обратитесь к администратору`,
					})
				)
			}
		}
	}

	const handlerFromArchive = () => {
		if ((!originalObj.locked && isAdmin) || (!originalObj.locked && !isAdmin && isAuthorProject)) {
			const { author, ...projectObjData } = projectObj
			const clone = Object.assign({}, projectObjData)
			clone.archive = 0
			delete clone.id
			delete clone.userId
			dispatch(saveUserProject(clone, false, projectObjData.id))
			setTimeout(() => history.push(`/user-projects/project/${projectObjData.id}/parameters`), 1100)
		} else if (!originalObj.locked && !isAdmin && !isAuthorProject) {
			dispatch(
				showNotify({
					type: 'warning',
					message: `У вас есть доступ для переноса расчетов, созданных вами. Для переноса расчета обратитесь к администратору`,
				})
			)
		} else if (originalObj.locked) {
			if (isAdmin) {
				dispatch(
					showNotify({
						type: 'warning',
						message: `Нельзя переместить данный расчета, так как он заблокирован. Для переноса сначала разблокируйте расчет`,
					})
				)
			} else {
				dispatch(
					showNotify({
						type: 'warning',
						message: `Нет прав на перемещение расчета. Для переноса расчета обратитесь к администратору`,
					})
				)
			}
		}
	}

	const handlerChangeCheckbox = (name: string, checked: boolean) => {
		if (name === 'techConnect') {
			dispatch(setTechConnect(checked))
		} else if (name === 'onlyPIR') {
			dispatch(setOnlyPIR(checked))
		}
	}

	const handlerLockUserProject = () => {
		if (isAdmin) {
			dispatch(setLocked(!projectObj.locked))
			if (!projectObj.locked === true) {
				dispatch(
					showNotify({
						type: 'success',
						message: `Расчет заблокирован, для применения изменений сохраните расчет`,
					})
				)
			} else {
				dispatch(
					showNotify({
						type: 'success',
						message: `Расчет разблокирован, для применения изменений сохраните расчет`,
					})
				)
			}
		} else {
			dispatch(
				showNotify({
					type: 'warning',
					message: `Нельзя заблокировать или разблокировать расчет. Для изменения статуса расчета обратитесь к администратору`,
				})
			)
		}
	}

	const openWindow = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			if (e.currentTarget.dataset.popup === 'select-f20' || e.currentTarget.dataset.popup === 'check-project') {
				setAnchorEl!(e.currentTarget)
				setAccessory!('check-list')
				if (accessoryRef) accessoryRef.current = accessory
			} else {
				const isDelete = e.currentTarget.dataset.popup === 'del-project'
				if (isDelete && originalObj.locked && isAdmin) {
					dispatch(
						showNotify({
							type: 'warning',
							message: `Нельзя удалить расчет, так как он заблокирован. Для удаление сначала разблокируйте расчет`,
						})
					)
				} else if (isDelete && originalObj.locked && !isAdmin) {
					dispatch(
						showNotify({
							type: 'warning',
							message: `Нельзя удалить расчет, так как он заблокирован. Для удаление расчета обратитесь к администратору`,
						})
					)
				} else if (isDelete && !originalObj.locked && !isAdmin && !isAuthorProject) {
					dispatch(
						showNotify({
							type: 'warning',
							message: `У вас есть доступ на удаление только своих расчетов. Для удаление расчета обратитесь к его автору или администратору`,
						})
					)
				} else if (
					(isDelete && !originalObj.locked && !isAdmin && isAuthorProject) ||
					(isDelete && !originalObj.locked && isAdmin)
				) {
					setAnchorEl!(e.currentTarget)
					setAccessory!('check-list')
					if (accessoryRef) accessoryRef.current = accessory
				}
			}
		},
		[setAnchorEl, isAdmin, isAuthorProject, originalObj]
	)

	useEffect(() => {
		const filteredFilial = filial.filter(filialEl => getCurrentRegionsIds?.includes(Number(filialEl.regionId)))
		setInitFilial(filteredFilial)
	}, [defaultRegions])

	useEffect(() => {
		if (!projectObj.id) {
			dispatch(setProjectYears(projectObj.unc_sort ? projectObj.unc_sort : 2018, currentYear))
			dispatch(updateDeflators(projectObj.unc_sort ? projectObj.unc_sort : 2018, currentYear))
		}
	}, [])

	useEffect(() => {
		if (!projectObj.id) dispatch(setProjectRegions(defaultRegionId))
	}, [defaultRegionId, projectObj.id, setProjectRegions])

	useEffect(() => {
		if (getCurrentRegions) setDefaultRegions(getCurrentRegions)
	}, [projectObj.regionIds, regions])

	useEffect(() => {
		const currentFilial = filial.filter(el => el.id == 999)[0]
		if (currentFilial) {
			dispatch(setProjectFilial(String(currentFilial.id)))
		}
		setDefaultFilial(currentFilial)
	}, [projectObj.filialId, filial])

	useEffect(() => {
		if (projectObj && projectObj.author) {
			const firstName = projectObj.author?.firstname
			const lastName = projectObj.author?.lastname
			const tel = JSON.parse(projectObj.author.data).phone

			const fullInfoAuthor = `${firstName} ${lastName}`
			setAuthorTel(tel)
			setAuthor(fullInfoAuthor)
		}
	}, [projectObj.author])

	const projectSaved = projectObj.id

	const totalInvestmentUncOffer = projectObj.totalInvestmentUncOffer == null ? 0 : projectObj.totalInvestmentUncOffer
	const valuationOfferForecast = projectObj.valuationOfferForecast == null ? 0 : projectObj.valuationOfferForecast
	const uncExceeding =
		totalInvestmentUncOffer > valuationOfferForecast
			? prettyCost((totalInvestmentUncOffer - valuationOfferForecast) / 10 ** 6)
			: '0.00'

	const red = totalInvestmentUncOffer < 0 ? { color: 'red' } : {}
	useEffect(() => {
		if (projectObj.unc_sort === '2024') {
			setIsNewVersion(true)
		} else {
			setIsNewVersion(false)
		}
	}, [projectObj.unc_sort])

	return (
		<div className='container'>
			<div className={`check-plate ${failure ? 'hidden' : ''}`}>
				<Grid container spacing={3} alignItems='flex-start'>
					<Grid item xs={12} spacing={3} justify='center'>
						<Box display='flex' alignItems='center' justifyContent={'end'} style={{ width: '100%' }}>
							{Number(projectObj.unc_sort) === 2024 ? (
								<a
									className='link-blue link-underline'
									href='https://www.garant.ru/products/ipo/prime/doc/408539773/'
									target='_blank'
									rel='noopener noreferrer'
								>
									Приказ Минэнерго
								</a>
							) : (
								<a className='link-blue link-underline' href='/assets/prikaz.pdf' download={'Приказ Минэнерго.pdf'}>
									Приказ Минэнерго
								</a>
							)}
						</Box>
					</Grid>
					<Grid item xs={6}>
						{projectObj.author ? (
							<div className='cell-label author-label'>
								<span style={{ color: 'gray' }}>Автор расчета:</span>
								<span className='mr-8 ml-4'>{author}</span>
								<PhoneIphoneIcon fontSize='small' color='disabled' />
								<span className='ml-2'>{authorTel}</span>
							</div>
						) : null}

						<Grid container spacing={1}>
							<Grid item xs>
								<input
									className='code check-plate-input'
									type='text'
									value={projectObj.code || ''}
									disabled={isArchived || isLocked}
									placeholder='Идентификатор'
									onChange={e => dispatch(setProjectCode(e.currentTarget.value.replace(/[^\S]/, '')))}
									onBlur={e => (e.target.value.trim() === '' ? e.target.classList.add('input-no-valid') : null)}
								/>
							</Grid>
							<Grid item xs>
								<input
									className='groupNum check-plate-input'
									type='text'
									value={projectObj.groupNum || ''}
									disabled={isArchived || isLocked}
									placeholder='Номер группы'
									onChange={e => dispatch(setProjectGroupNum(e.currentTarget.value))}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3}>
						<Grid item xs>
							<div className='checkbox-wrapper'>
								<CheckboxItem
									checked={Boolean(projectObj.onlyPIR)}
									handler={handlerChangeCheckbox}
									disabled={isArchived || isLocked}
									label={'только ПИР'}
									name={'onlyPIR'}
								/>
								<CheckboxItem
									checked={Boolean(projectObj.techConnect)}
									handler={handlerChangeCheckbox}
									disabled={isArchived || isLocked}
									label={'тех.присоединение'}
									name={'techConnect'}
								/>
							</div>
						</Grid>
						{originalObj.versions?.length ? (
							<Box display='flex' alignItems='center' style={{ gap: 12 }}>
								<Input
									inputValue={projectObj.version == 'УНЦ 2018' ? 'УНЦ 2019' : projectObj.version}
									placeholderValue='Версия'
									disabled={isArchived || isLocked}
									handleInput={e => {
										dispatch(changeVersionName(e.target.value))
									}}
									classes='full-width'
								/>

								<PopupState variant='popover' popupId='demo-popup-popover'>
									{popupState => (
										<div>
											<IconButton {...bindTrigger(popupState)} size='medium' style={{ padding: 0 }}>
												<ListIcon fontSize='large' />
											</IconButton>
											<Popover
												{...bindPopover(popupState)}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'center',
												}}
											>
												<Box display='flex' flexDirection='column'>
													<Box p={2} display='flex' style={{ gap: 6 }}>
														<Input
															inputValue={newAddedVersionName}
															placeholderValue='Добавить версию'
															disabled={isArchived || isLocked}
															handleInput={e => {
																setNewAddedVersionName(e.target.value)
															}}
															classes='full-width'
														>
															<IconButton
																size='small'
																onClick={() => setIsOpenAddVersionDate(true)}
																disabled={isArchived || isLocked}
															>
																<InsertInvitationIcon fontSize='small' color='primary' />
															</IconButton>
														</Input>
														<Button
															size='small'
															disabled={newAddedVersionName.length < 3 || isArchived || isLocked}
															color='primary'
															variant='contained'
															onClick={() => {
																dispatch(AddNewVersion(projectObj, newAddedVersionName, typePageUrl))
																setNewAddedVersionName('')
															}}
														>
															<AddIcon fontSize='small' style={{ color: 'white' }} />
														</Button>
														<DatePicker
															open={isOpenAddVersionDate}
															onOpen={() => setIsOpenAddVersionDate(true)}
															onClose={() => setIsOpenAddVersionDate(false)}
															value={selectedDate}
															onChange={handleDateChange}
															TextFieldComponent={() => null}
														/>
													</Box>
													<List>
														{(originalObj?.versions as {
															userProjectId: number
															name: string
															is_actual: boolean
															unc_sort: string
														}[])?.map(
															(version: {
																userProjectId: number
																name: string
																is_actual: boolean
																unc_sort: string
															}) => (
																<ListItem
																	style={{ paddingLeft: 16, paddingRight: 16 }}
																	key={version.userProjectId}
																	button
																	component={Link}
																	selected={version.userProjectId == userProjectId}
																	to={`/${'user-projects'}/project/${version.userProjectId}/parameters`}
																>
																	<ListItemText primary={version.name == 'УНЦ 2018' ? 'УНЦ 2019' : version.name} />
																	<Chip size='small' label={version.unc_sort == '2018' ? '2019' : version.unc_sort} />
																</ListItem>
															)
														)}
													</List>
												</Box>
											</Popover>
										</div>
									)}
								</PopupState>
							</Box>
						) : null}
					</Grid>

					<Grid item xs={3}>
						<Grid container spacing={1} direction='column' alignItems='flex-end'>
							<Box display='flex' alignItems='center' style={{ gap: 12 }}>
								<button
									style={{ overflow: 'hidden', maxWidth: 32, width: '100%' }}
									onClick={() => handlerLockUserProject()}
								>
									<div className={`${projectObj.locked ? 'lock-icon' : 'unlock-icon'}`}></div>
								</button>
							</Box>
							{projectObj.id && (
								<Grid item xs>
									<div className='check-plate-row-right'>
										<div className='user-projects__options'>
											{/*
											<IconButton onClick={() => dispatch(downloadForm20(projectObj.id!, projectObj.code))}>
												<span className='download-icon'></span>
											</IconButton>
											*/}
											<IconButton data-popup='select-f20' onClick={openWindow}>
												<span className='download-icon' title='Скачать Форму 20'></span>
											</IconButton>
											{!isArchived ? (
												<IconButton onClick={() => dispatch(copyProject(projectObj.id!, `${projectObj.code}_COPY`))}>
													<span className='copy-icon' title='Копировать'></span>
												</IconButton>
											) : null}
											<IconButton data-popup='del-project' onClick={openWindow}>
												<span className='delete-icon' title='Удалить'></span>
											</IconButton>
											{anchorEl?.dataset.popup === 'del-project' && (
												<DeleteProjectWindow
													anchorEl={anchorEl}
													setAnchorEl={setAnchorEl!}
													handler={handlerClickDeletePopup}
												/>
											)}
											{anchorEl?.dataset.popup === 'select-f20' && (
												<SelectF20Window
													anchorEl={anchorEl}
													setAnchorEl={setAnchorEl!}
													handler1={handlerClickDownloadF20Popup1}
													handler2={handlerClickDownloadF20Popup2}
												/>
											)}
										</div>
										{!typePageUrl.includes('user-archive') ? (
											<PrimaryBtn onClick={handlerToArchive}>Перенести в Архив &#8594;</PrimaryBtn>
										) : (
											<PrimaryBtn onClick={handlerFromArchive}>&#8592; Перенести в Расчеты</PrimaryBtn>
										)}
									</div>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid container spacing={3} alignItems='flex-end'>
					<Grid item xs>
						<textarea
							placeholder='Наименование инвестиционного объекта'
							disabled={isArchived || isLocked}
							value={projectObj.name || ''}
							onChange={e => dispatch(setProjectName(e.currentTarget.value))}
							className='check-plate-textarea'
						/>
					</Grid>
					<Grid item xs>
						<textarea
							onChange={e => dispatch(setProjectRationale(e.currentTarget.value))}
							spellCheck='false'
							disabled={isArchived || isLocked}
							value={projectObj.offerRationale || ''}
							placeholder='Комментарии'
							className='check-plate-textarea'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} alignItems='flex-end'>
					<Grid item xs={3}>
						<LabelInput tooltip='Субъект РФ' title='Субъект РФ' />
						<Select
							subject={true}
							list={[...regions].sort((a, b) => a.searchName.localeCompare(b.searchName))}
							placeholder='Выберете'
							tooltip='Субъект РФ'
							multy={true}
							disabled={isArchived || isLocked}
							changeHandler={val => {
								const regionIds = val.map((region: IRegion) => region.id)
								dispatch(setProjectRegions(regionIds))
							}}
							defaultVal={defaultRegions}
						/>
					</Grid>
					<Grid item xs={3}>
						<LabelInput tooltip='Филиал/подразделение' title='Филиал/подразделение' />
						<Select
							list={initFilial}
							placeholder='Все филиалы'
							tooltip='Филиал'
							multy={false}
							disabled={true}
							defaultVal={defaultFilial}
						/>
					</Grid>
					<Grid item xs={6}>
						<LabelInput tooltip='Индексы дефляторы' title='Индексы дефляторы' />
						<CarouselWide
							fullWidth={true}
							disabled={true}
							code='deflator'
							dataArr={deflators}
							title='Индексы дефляторы'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} alignItems='flex-end'>
					<Grid item xs={3}>
						<LabelInput tooltip='Период реализации' title='Период реализации' />
						<CalendarInterval
							placeholder='Период реализации'
							changeHandler={(value: number[]) => {
								dispatch(setProjectYears(value[0], value[1]))
								if (value[0] && value[1]) {
									dispatch(updateDeflators(value[0], value[1]))
								}
							}}
							defaultVal={[projectObj.yearBegin, projectObj.yearEndOffer]}
							wrapClass='no_padding year_interval'
							tooltip='Период реализации'
							format='yyyy'
							views={['year']}
							dateCorrectLength={4}
							isDisabled={isArchived || isLocked}
							min={projectObj.yearBegin ? projectObj.yearBegin : '1985'}
							max={projectObj.yearEndOffer ? projectObj.yearEndOffer : '2050'}
						/>
					</Grid>
					<Grid item xs={3} zeroMinWidth>
						<LabelInput
							tooltip='20.1_7 Текущая стадия реализации (этапа) инвестиционного проекта'
							title='Текущая стадия реализации (этапа) инвестиционного проекта'
						/>
						<div className='full-width'>
							<input
								className='universal-input'
								type='text'
								value={projectObj.phase || ''}
								disabled={isArchived || isLocked}
								placeholder='Введите'
								onChange={e => dispatch(setProjectPhase(e.currentTarget.value))}
							/>
						</div>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip='Объем финансирования инвестиций по инвестиционному проекту (в прогнозных ценах с&nbsp;НДС)'
							title='Объем финансирования инвестиций по годам (с&nbsp;НДС)'
						/>
						<CarouselWide
							fullWidth={false}
							code='offer'
							disabled={isArchived || isLocked}
							numberTransformation={true}
							dataArr={years}
							totalInvestmentUncOffer={projectObj.totalInvestmentUncOffer && projectObj.totalInvestmentUncOffer}
							title='Объем финансирования по годам, млн.р (с&nbsp;НДС)'
						/>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip='20.3_13 Фактический объем финансирования инвестиций по инвестиционному проекту (с&nbsp;НДС)'
							title={`Факт. объем финансирования на\u00A0 ${
								projectObj.unc_sort === '2018' ? '01.01.2018' : '01.01.2023'
							} (с\u00A0.НДС)`}
						/>
						<InputBox
							inputValue={projectObj.actualFunding ? projectObj.actualFunding / 10 ** 6 : '0'}
							placeholderValue='Введите'
							disabled={isArchived || isLocked}
							changeFunc={(value: string) => {
								dispatch(setProjectActual(+value * 10 ** 6))
							}}
							InputIntShow={true}
							classes='full-width'
							precision={precision}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} alignItems='flex-end'>
					<Grid item xs={3}>
						<LabelInput
							tooltip='20.1_11 Наименование документа, согласно которому утверждены технологические решения'
							title='Наименование документа, согласно которому утверждены технологические решения'
						/>
						<div className='full-width'>
							<input
								className='universal-input'
								type='text'
								value={projectObj.decreeName || ''}
								disabled={isArchived || isLocked}
								placeholder='Введите'
								onChange={e => dispatch(setProjectDecreeName(e.currentTarget.value))}
							/>
						</div>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip='Реквизиты документа, согласно которому утверждены технологические решения'
							title='Реквизиты документа, согласно которому утверждены технологические решения'
						/>

						<div className='full-width'>
							<input
								className='universal-input'
								type='text'
								value={projectObj.decreeNumber || ''}
								disabled={isArchived || isLocked}
								placeholder='Введите'
								onChange={e => dispatch(setProjectDecreeNumber(e.currentTarget.value))}
							/>
						</div>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip='Оценка полной стоимости в прогнозных ценах соотв. лет (с&nbsp;НДС)'
							title='Оценка полной стоимости в прогнозных ценах соотв. лет (с&nbsp;НДС)'
						/>
						<InputBox
							inputValue={projectObj.valuationOfferForecast ? projectObj.valuationOfferForecast / 10 ** 6 : '0'}
							placeholderValue='Введите'
							disabled={isArchived || isLocked}
							changeFunc={(value: string) => {
								dispatch(setProjectValuation(+value * 10 ** 6))
							}}
							InputIntShow={true}
							classes='full-width'
							precision={precision}
						/>
					</Grid>

					<Grid item xs={3}>
						<LabelInput
							tooltip='Сумма ненормируемых затрат (с&nbsp;НДС)'
							title='Сумма ненормируемых затрат (с&nbsp;НДС)'
						/>
						<InputBox
							inputValue={projectObj.additionalFundingOffer ? projectObj.additionalFundingOffer / 10 ** 6 : '0'}
							placeholderValue=''
							disabled={true}
							changeFunc={(value: string) => {
								dispatch(setProjectAdditional(+value * 10 ** 6))
							}}
							InputIntShow={true}
							classes='full-width'
							precision={precision}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} alignItems='flex-end'>
					<Grid item xs={!isArchived ? 10 : 11}></Grid>
					{!isArchived ? <Grid item xs={1}></Grid> : null}
					<Grid item xs={1}>
						<div>
							<button
								style={{ display: 'flex' }}
								aria-controls='table-menu'
								aria-haspopup='true'
								onClick={e => handleClick(e)}
							>
								<div className='purchase-icon'></div>
								<span className='cell-file'>Таблица</span>
							</button>
							<Menu
								id='table-menu'
								anchorEl={anchorElTable}
								keepMounted
								open={Boolean(anchorElTable)}
								onClose={handleClose}
							>
								{!isNewVersion && (
									<MenuItem
										className='pointer flex justify-content-center'
										data-popup='open-table'
										onClick={e => {
											dispatch(getT7Data(userProjectId, 1))
											setAnchorEl!(e.currentTarget)
											setAccessory!('open-table')
											handleClose()
											typePageUrl === 'user-archive'
												? dispatch(setT7EditModeArchive(!t7EditMode))
												: dispatch(setT7EditMode(!t7EditMode))
										}}
									>
										по форме Постановления Правительства Российской Федерации от 12.11.2016 № 1157
									</MenuItem>
								)}
								<MenuItem
									className='pointer flex justify-content-center'
									data-popup='open-table-form20'
									onClick={e => {
										if (projectSaved) {
											dispatch(getT7Data(userProjectId, 2))
											setAnchorEl!(e.currentTarget)
											setAccessory!('open-table-form20')
											handleClose()
											typePageUrl === 'user-archive'
												? dispatch(setT7EditModeArchive(!t7EditMode))
												: dispatch(setT7EditMode(!t7EditMode))
										} else {
											dispatch(
												showNotify({
													type: 'warning',
													message: `Сначала необходимо сохранить проект`,
												})
											)
										}
									}}
								>
									По форме вкладки 20.2 формы 20
								</MenuItem>
							</Menu>
						</div>

						{anchorEl?.dataset.popup === 'select-an-action' &&
							(!projectSaved ? (
								<div className='modal_over'>Необходимо сохранить проект</div>
							) : (
								<SelectAction
									anchorEl={anchorEl!}
									setAnchorEl={setAnchorEl!}
									getTplUnnormalizedСosts={() =>
										!isArchived
											? dispatch(getTplUnnormalizedСosts(projectObj.id!))
											: dispatch(getTplUnnormalizedСostsArchive(projectObj.id!))
									}
									uploaderUnnormalizedСosts={e =>
										!isArchived
											? dispatch(uploaderUnnormalizedСosts(e.target.files, projectObj.id!))
											: dispatch(uploaderUnnormalizedСostsArchive(e.target.files, projectObj.id!))
									}
									getUnnormalizedСosts={() =>
										!isArchived
											? dispatch(getUnnormalizedСosts(projectObj.id!))
											: dispatch(getUnnormalizedСostsArchive(projectObj.id!))
									}
									deleteUnnormalizedСosts={() =>
										!isArchived
											? dispatch(deleteUnnormalizedСosts(projectObj.id!))
											: dispatch(deleteUnnormalizedСostsArchive(projectObj.id!))
									}
									t7Id={projectObj.t7Id!}
								/>
							))}
						{anchorEl?.dataset.popup === 'open-table' && (
							<UnNormalizedCosts
								isArchived={isArchived}
								isLocked={isLocked}
								anchorEl={anchorEl!}
								setAnchorEl={setAnchorEl!}
								t7EditMode={t7EditMode}
							/>
						)}
						{anchorEl?.dataset.popup === 'open-table-form20' && (
							<UnNormalizedForm20Costs
								isArchived={isArchived}
								isLocked={isLocked}
								anchorEl={anchorEl!}
								setAnchorEl={setAnchorEl!}
								t7EditMode={t7EditMode}
								isNewVersion={isNewVersion}
							/>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={3} alignItems='flex-end'>
					<Grid item xs={3}>
						<LabelInput tooltip={`Дефицит по проекту, млн.р. (с НДС)`} title='Дефицит по проекту (с&nbsp;НДС)' />
						<div className='cell__cost-info'>
							{uncExceeding || uncExceeding === 0 ? (
								<>
									<div> {`${prettyCost(uncExceeding)}`} </div>
									<span>млн.р</span>
								</>
							) : (
								<span className='no_cost'>Значение отсутствует</span>
							)}
						</div>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip='20.3_8 То же, в прогнозных ценах соответствующих лет, млн рублей (с&nbsp;НДС)'
							title='Объем финансовых потребностей по УНЦ в прогнозных ценах (с&nbsp;НДС)'
						/>
						<div className='cell__cost-info'>
							{projectObj.totalInvestmentUncOffer || projectObj.totalInvestmentUncOffer === 0 ? (
								<>
									<div style={red}>
										{`${prettyCost(
											Number(intFormat(projectObj.totalInvestmentUncOffer / 10 ** 6, true, precision + 1)).toFixed(
												precision
											),
											precision
										)}`}
									</div>
									<span>млн.р</span>
								</>
							) : (
								<span className='no_cost'>Значение отсутствует</span>
							)}
						</div>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip={
								<div>
									20.4_7 Итого, ОФПУНЦ в текущих ценах, млн рублей (с&nbsp;НДС) <br />
									{`Объем финансовых потребностей в ценах ${currentYear} года составляет ${
										calcDeflators(currentYear) ? calcDeflators(currentYear) : 0
									} млн.руб (с НДС)`}
								</div>
							}
							title={`Итого ОФП по УНЦ в текущих ценах на\u00A0 ${
								projectObj.unc_sort === '2018' ? '01.01.2018' : '01.01.2023'
							} (с\u00A0НДС)`}
						/>
						<div className='cell__cost-info'>
							{projectObj.sumUncCostWithNds || projectObj.sumUncCostWithNds === 0 ? (
								<>
									<div>{`${prettyCost(projectObj.sumUncCostWithNds / 10 ** 6, precision)}`}</div>
									<span>млн.р</span>
								</>
							) : (
								<span className='no_cost'>Значение отсутствует</span>
							)}
						</div>
					</Grid>
					<Grid item xs={3}>
						<Grid container spacing={1} alignItems='center'>
							<Grid item xs>
								<LabelInput
									tooltip='20.3_6 Итого, ОФП УНЦ в текущих ценах, млн рублей (без НДС)'
									title={`Итого ОФП по УНЦ в текущих ценах на\u00A0 ${
										projectObj.unc_sort === '2018' ? '01.01.2018' : '01.01.2023'
									} (без\u00A0.НДС)`}
								/>
								<div className='cell__cost-info'>
									{projectObj.sumUncCost || projectObj.sumUncCost === 0 ? (
										<>
											<div>{`${prettyCost(projectObj.sumUncCost / 10 ** 6, precision)}`}</div>
											<span>млн.р</span>
										</>
									) : (
										<span className='no_cost'>Значение отсутствует</span>
									)}
								</div>
							</Grid>
							<Grid item xs={3}>
								<div className='cell-item-right__inner'>
									<div className='cell-item-label'>НДС</div>
									<input
										type='text'
										disabled={true}
										value={projectObj.nds ? projectObj.nds * 100 : ''}
										className='check-plate-input '
									/>
									<div>%</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={3} alignItems='flex-end'>
					<Grid item xs={3}>
						<LabelInput
							tooltip={`Превышение РПС над УНЦ, млн.р. (с НДС)`}
							title='Превышение РПС над УНЦ (с&nbsp;НДС)'
						/>
						<div className='cell__cost-info'>
							{projectObj.rps_unc_exceeding || projectObj.rps_unc_exceeding === 0 ? (
								<>
									<div> {`${prettyCost(projectObj.rps_unc_exceeding / 10 ** 6)}`} </div>
									<span>млн.р</span>
								</>
							) : (
								<span className='no_cost'>Значение отсутствует</span>
							)}
						</div>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip='Объем финансовых потребностей по РПС (ССР, О-А, ТКП) в прогнозных ценах (с&nbsp;НДС)'
							title='Объем финансовых потребностей по РПС (ССР, О-А, ТКП) в прогнозных ценах (с&nbsp;НДС)'
						/>
						<div className='cell__cost-info'>
							{projectObj.financial_needs_forecast || projectObj.financial_needs_forecast === 0 ? (
								<>
									<div style={red}> {`${intFormat(projectObj.financial_needs_forecast / 10 ** 6, true)}`} </div>
									<span>млн.р</span>
								</>
							) : (
								<span className='no_cost'>Значение отсутствует</span>
							)}
						</div>
					</Grid>
					<Grid item xs={3}>
						<LabelInput
							tooltip='Итого ОФП по РПС (ССР, О-А, ТКП) в текущих ценах (с&nbsp;НДС)'
							title={`Итого ОФП по РПС (ССР, О-А, ТКП) в текущих ценах на\u00A0 ${
								projectObj.unc_sort === '2018' ? '01.01.2018' : '01.01.2023'
							} (с\u00A0.НДС)`}
						/>
						<InputBox
							inputValue={projectObj.financial_needs_withNds ? projectObj.financial_needs_withNds / 10 ** 6 : '0'}
							placeholderValue='Введите'
							disabled={isArchived || isLocked}
							changeFunc={(value: string) => {
								dispatch(setProjectFinancialNeeds(+value * 10 ** 6))
							}}
							InputIntShow={true}
							classes='full-width'
							precision={precision}
						/>
					</Grid>
					<Grid item xs={3}>
						<Grid container spacing={1} alignItems='center'>
							<Grid item xs>
								<LabelInput
									tooltip='Итого, ОФП по РПС (ССР, О-А, ТКП) в текущих ценах, млн рублей (без НДС)'
									title='Итого ОФП по РПС (ССР, О-А, ТКП) в текущих ценах (без НДС)'
								/>
								<div className='cell__cost-info'>
									{projectObj.financial_needs || projectObj.financial_needs === 0 ? (
										<>
											<div> {`${prettyCost(projectObj.financial_needs / 10 ** 6)}`} </div>
											<span>млн.р</span>
										</>
									) : (
										<span className='no_cost'>Значение отсутствует</span>
									)}
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{!isArchived ? (
					<div className='wrapper-controls flex'>
						<div className='checkproject_wrapper'>
							<PrimaryBtn onClick={handlerClickSave}>Рассчитать и сохранить</PrimaryBtn>
							{anchorEl?.dataset.popup === 'check-project' && (
								<CheckProjectWindow
									setAnchorEl={setAnchorEl!}
									anchorEl={anchorEl}
									sectionIds={sectionIds}
									sectionIdsArr={sectionIdsArr}
									setAccessory={setAccessory}
									accessory={accessory}
									accessoryRef={accessoryRef}
									handler={() => {
										setAccessory!('error-list')
										if (accessoryRef) accessoryRef.current = accessory
										dispatch(getCheckedErrors(projectObj.id, selectedSectionIds))
									}}
									handlerAutoAddUnc={() => {
										dispatch(autoAddUnc(projectObj.id, selectedSectionIds))
									}}
								/>
							)}
						</div>
						<div className='ml-8'>
							<SecondaryBtn onClick={openWindow} data-popup='check-project'>
								Проверить
							</SecondaryBtn>
						</div>
					</div>
				) : null}
			</div>
			{failure && <FailurePage />}
		</div>
	)
}

export { CheckPlate }
