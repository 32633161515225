import React, { FC, useEffect, useMemo, useState } from 'react'
import {
	Box,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	makeStyles,
	styled,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { ICompany, IRegion, IState } from '@root-gipro/store/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { ROLE_OWNER } from '@root-gipro/modules/AdminPanel/store/helpers'
import {
	deleteCompanyIpr,
	setCompanyState,
	setOpenModalCreateCompany,
	updateCompanyIpr,
	сreateCompanyIpr,
} from '@root-gipro/modules/AdminPanel/store/actions'
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp'

interface IAdminPanelTableCompanyRow {
	company: ICompany
	index: number
}

const useStyles = makeStyles(() => ({
	select: {
		maxWidth: 200,
		background: '#f5f5f5',
		borderRadius: '5px',
		'& .MuiSelect-select': {
			'&:hover': {
				backgroundColor: '#fff',
			},
		},
		paper: {
			width: 300,
		},
	},
}))

const CustomRadio = styled(Radio)(({ theme }) => ({
	color: '#c4c4c4', // Светло-серый цвет бордера
	'&.Mui-checked': {
		color: '#1BAAF0', // Цвет при активации
	},
	'& .MuiSvgIcon-root': {
		borderRadius: '50%', // Закругленные края
		border: '1px solid #c4c4c4', // Тонкий светло-серый бордер
	},
	'&:hover': {
		backgroundColor: 'transparent', // Убираем фон при наведении
	},
}))

const AdminPanelTableCompanyRow: FC<IAdminPanelTableCompanyRow> = ({ company, index }) => {
	const { regions } = useSelector((state: IState) => state.public)
	const classes = useStyles()
	const roleId = localStorage.getItem('roleId')
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false)
	const [openDeleteW, setOpenDeleteW] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)
	const [selectedVersion, setSelectedVersion] = useState(null)
	const [newVersion, setNewVersion] = useState(
		isEditMode
			? {
					version_name: '',
					ipr_start_year: '',
					ipr_end_year: '',
					deflator_precision: '',
					is_active: 0,
			  }
			: {
					version_name: '',
					ipr_start_year: '',
					ipr_end_year: '',
					deflator_precision: '',
			  }
	)
	const [editVersion, setEditVersion] = useState({
		version_name: '',
		ipr_start_year: '',
		ipr_end_year: '',
		deflator_precision: '',
		is_active: 0,
	})
	const [initialVersion, setInitialVersion] = useState(null)

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

	const updateCompanyData = () => {
		dispatch(setOpenModalCreateCompany({ open: true, mode: 'updateCompany' }))
		dispatch(setCompanyState(company))
	}

	const getCurrentRegions: any = useMemo(() => {
		try {
			if (regions) return regions?.filter((region: IRegion) => company?.regionIds?.indexOf(region.id) !== -1)
		} catch (error) {
			console.log(error)
		}
	}, [company, regions])

	const defaultLabel = () => {
		if (getCurrentRegions?.length > 1) {
			return `${getCurrentRegions[0]?.name} +${getCurrentRegions.length - 1} региона`
		} else if (getCurrentRegions[0]) {
			return getCurrentRegions[0]?.name
		} else {
			return ''
		}
	}

	useEffect(() => {
		// Проверка для добавления новой версии — если хотя бы одно поле пустое, кнопка дизейблится
		const isAddingNew = !initialVersion
		if (isAddingNew) {
			const isAnyFieldEmpty = Object.values(newVersion).some(value => value === '')
			setIsSubmitDisabled(isAnyFieldEmpty)
		} else {
			// Проверка для редактирования — если не было изменений, кнопка дизейблится
			// @ts-ignore
			const isVersionChanged = Object.keys(editVersion).some(key => editVersion[key] !== initialVersion[key])
			setIsSubmitDisabled(!isVersionChanged)
		}
	}, [newVersion,editVersion, initialVersion])

	//  модалка для добавления новой версии
	const handleOpenAddModal = () => {
		setIsEditMode(false)
		setNewVersion({
			version_name: '',
			ipr_start_year: '',
			ipr_end_year: '',
			deflator_precision: '',
		})
		setInitialVersion(null);
		setModalOpen(true)
	}

	//  модалка для редактирования версии
	const handleOpenEditModal = (version: any) => {
		setIsEditMode(true)
		setSelectedVersion(version)
		setEditVersion({
			version_name: version.version_name,
			ipr_start_year: version.ipr_start_year,
			ipr_end_year: version.ipr_end_year,
			deflator_precision: version.deflator_precision,
			is_active: version.is_active,
		})
		setInitialVersion(version);
		setModalOpen(true)
	}

	const handleCloseModal = () => {
		setModalOpen(false)
	}

	// Обработка ввода данных в форму
	const handleInputChange = (e: any) => {
		const { name, value } = e.target
		if (isEditMode) {
			setEditVersion(prev => ({
				...prev,
				[name]: value,
			}))
		} else {
			setNewVersion(prev => ({
				...prev,
				[name]: value,
			}))
		}
	}

	// Обработка добавления версии
	const handleAddVersionSubmit = () => {
		dispatch(сreateCompanyIpr({ company_id: company.id, ...newVersion }))
		setModalOpen(false)
	}

	// Обработка редактирования версии
	const handleEditVersionSubmit = () => {
		// @ts-ignore
		dispatch(updateCompanyIpr({ id: selectedVersion?.id, ...editVersion }))
		setModalOpen(false)
	}
	const handleActiveChange = (id: number) => {
		dispatch(updateCompanyIpr({ id, is_active: true }))
	}
	return (
		<>
			<TableRow className='adminPanel__tableRow'>
				{roleId && Number(roleId) <= ROLE_OWNER ? (
					<TableCell className='adminPanelCell' align='center'>
						<IconButton
							title='Редактировать'
							size='small'
							type='button'
							onClick={() => {
								updateCompanyData()
							}}
							aria-label='Изменить данные'
						>
							<EditIcon fontSize='small' />
						</IconButton>
					</TableCell>
				) : (
					''
				)}
				<TableCell className='adminPanelCell'>{++index}</TableCell>
				<TableCell className='adminPanelCell'>{company.name}</TableCell>
				<TableCell className='adminPanelCell'>
					{
						<ArrowForwardIosSharpIcon
							onClick={() => setOpen(!open)}
							style={{
								transform: open ? 'rotate(-90deg)' : 'rotate(90deg)',
								transition: 'transform 0.3s ease',
								color: 'gray',
								cursor: 'pointer',
								margin: '0 25px',
							}}
						/>
					}
				</TableCell>
				<TableCell className='adminPanelCell' align='left'>
					<span title={company?.full_name}>{company?.full_name}</span>
				</TableCell>
				<TableCell className='adminPanelCell'>{company?.deflator_precision}</TableCell>
				<TableCell className='adminPanelCell'>
					{company?.ipr_start_year}-{company?.ipr_end_year}
				</TableCell>
				<TableCell className='adminPanelCell'>{company?.inn || <>&mdash;</>}</TableCell>
				<TableCell className='adminPanelCell'>{company?.kpp || <>&mdash;</>}</TableCell>
				<TableCell className='adminPanelCell'>{company?.ogrn || <>&mdash;</>}</TableCell>
				<TableCell className='adminPanelCell' align='left'>
					<span title={company?.okato}>{company?.okato || <>&mdash;</>}</span>
				</TableCell>
			
				<TableCell className='adminPanelCell' align={'left'}>
					{getCurrentRegions?.length ? (
						<FormControl fullWidth>
							<InputLabel title={defaultLabel()} style={{ width: '100%' }} id='custom-select-label'>
								{defaultLabel()}
							</InputLabel>
							<Select
								labelId='custom-select-label'
								value={getCurrentRegions[0]?.name}
								IconComponent={() => null}
								displayEmpty
								className={classes.select}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								{getCurrentRegions?.map((region: IRegion) => (
									<MenuItem
										style={{ minWidth: '200px', maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis' }}
										key={region?.id}
										value={region?.name}
									>
										<span title={region.name}>{region.name}</span>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					) : (
						<span>&mdash;</span>
					)}
				</TableCell>
			</TableRow>

			{/* Скрытый контент аккардеона */}

			<TableRow style={{ backgroundColor: '#8787870a' }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box margin={1}>
							<Typography variant='h6' gutterBottom component='div'>
								Версии ИПР
							</Typography>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										<TableCell>Действия</TableCell>
										<TableCell>Название</TableCell>
										<TableCell>Активная</TableCell> {/* Добавляем заголовок для радиокнопок */}
										<TableCell>Период ИПР</TableCell>
										<TableCell>Точность</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										// @ts-ignore
										company?.ipr?.map(version => {
											return (
												<TableRow
													key={version.id}
													// style={{ backgroundColor: `${version?.is_active == 1 ? 'rgb(27 170 240 / 23%)' : 'none'}` }}
												>
													<TableCell className='adminPanelCell' align='left' style={{ display: 'flex', gap: '50px' }}>
														<IconButton
															title='Редактировать'
															size='small'
															type='button'
															onClick={() => handleOpenEditModal(version)}
															aria-label='Изменить данные'
														>
															<EditIcon fontSize='small' />
														</IconButton>
														<IconButton
															title='Удалить'
															size='small'
															type='button'
															onClick={() => {
																setSelectedVersion(version)
																setOpenDeleteW(true)
															}}
															aria-label='Удалить данные'
														>
															<DeleteIcon fontSize='small' />
														</IconButton>
													</TableCell>
													<TableCell>{version.version_name}</TableCell>
													{/* Радиокнопка для активной версии */}
													<TableCell>
														<CustomRadio
															checked={version?.is_active}
															onChange={e => handleActiveChange(version.id)} // Функция для изменения активной версии
															value={version.id}
															name='activeVersion'
															inputProps={{ 'aria-label': 'Активная версия' }}
															style={{ margin: '0 25px' }}
														/>
													</TableCell>
													<TableCell>{`${version.ipr_start_year} - ${version.ipr_end_year}`}</TableCell>
													<TableCell>{version.deflator_precision}</TableCell>
												</TableRow>
											)
										})
									}
								</TableBody>
							</Table>
							<div>
								<Button
									variant='contained'
									color='primary'
									onClick={handleOpenAddModal}
									style={{ marginTop: '10px', color: 'white' }}
								>
									Добавить
								</Button>
							</div>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>

			{/* Модальное окно для добавления и редактирования */}
			<Dialog open={modalOpen} onClose={handleCloseModal}>
				<DialogTitle>{isEditMode ? 'Редактировать ИПР' : 'Добавить ИПР'}</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin='dense'
						label='Название версии'
						type='text'
						name='version_name'
						value={isEditMode ? editVersion.version_name : newVersion.version_name}
						onChange={handleInputChange}
						fullWidth
					/>
					<TextField
						margin='dense'
						label='Год начала ИПР'
						type='number'
						name='ipr_start_year'
						value={isEditMode ? editVersion.ipr_start_year : newVersion.ipr_start_year}
						onChange={handleInputChange}
						fullWidth
					/>
					<TextField
						margin='dense'
						label='Год окончания ИПР'
						type='number'
						name='ipr_end_year'
						value={isEditMode ? editVersion.ipr_end_year : newVersion.ipr_end_year}
						onChange={handleInputChange}
						fullWidth
					/>
					<TextField
						margin='dense'
						label='Точность'
						type='number'
						name='deflator_precision'
						value={isEditMode ? editVersion.deflator_precision : newVersion.deflator_precision}
						onChange={handleInputChange}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseModal} color='primary'>
						Отмена
					</Button>
					<Button disabled={isSubmitDisabled} onClick={isEditMode ? handleEditVersionSubmit : handleAddVersionSubmit} color='primary'>
						{isEditMode ? 'Сохранить изменения' : 'Добавить'}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openDeleteW}
				onClose={() => setOpenDeleteW(false)}
			>
				<DialogTitle>Подтверждение удаления</DialogTitle>
				<DialogContent>
					<DialogContentText>Вы точно хотите удалить версию ИПР?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDeleteW(false)} color='primary'>
						Отмена
					</Button>

					<Button
						onClick={() => {
							// @ts-ignore
							dispatch(deleteCompanyIpr(selectedVersion?.id))
							setOpenDeleteW(false)
						}}
						color='secondary'
						autoFocus
					>
						Удалить
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default AdminPanelTableCompanyRow
