// правильно сделать в компоненте интерфейсы и передачу пропсов/интерфейсов
import React, { useState, useCallback, memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Tab, TabsGroup } from '@root-gipro/shared/TabsComponents/CustomTabs'
import { ITabsNavLink } from '@root-gipro/shared/TabsComponents/tabs.props'
import { usePageLink } from '@root-gipro/shared/hooks/usePageLink'
import style from '@root-gipro/shared/TabsComponents/TabsComponents.module.scss'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { fetchPublicUserProjectInfo } from '@root-gipro/store/actions'

export const TabsNavLink: React.FC<ITabsNavLink> = memo(({ types, path }) => {
	const { currentPath }: any = usePageLink()
	const dispatch = useDispatch()
	const { projectObj } = useSelector((state: IState) => state.userProjects)

	const [selectedTab, setSelectedTab] = useState<string>(currentPath)
	useEffect(() => {
		setSelectedTab(currentPath)
	}, [currentPath])

	useEffect(() => {
		if (projectObj.unc_sort) {
			dispatch(fetchPublicUserProjectInfo(projectObj.unc_sort))
		}
	}, [projectObj.unc_sort])

	const history = useHistory()

	const handleChange = useCallback(
		(_: React.ChangeEvent<{}>, newRoute: string) => {
			history.push(newRoute)
			setSelectedTab(newRoute)
		},
		[types]
	)

	return (
		<TabsGroup value={selectedTab} onChange={handleChange} className={style.checkPlateTabs}>
			{types &&
				types.map((type: any) => {
					return (
						type.tags.includes(path) && (
							<Tab
								className={cn({
									[style.active]: selectedTab === type.path,
								})}
								disableRipple
								key={type.path}
								label={type.title}
								value={type.path}
								disabled={!projectObj?.id}
							/>
						)
					)
				})}
		</TabsGroup>
	)
})
