import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { UserProjectsType } from '@root-gipro/modules/userProjects/models/interfaces/user-project.actions'
import {
	IAllRequestProject,
	ICheckedErrors,
	ICheckedMassErrors,
	IRequestProject,
	IT7Data,
	IUnc,
	IUncAddBuffer,
	IUncCell,
	IUncTableRequest,
	IUnnormalizedListItem,
	IUserProject,
} from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import {
	getAssistStatus,
	getUpdateProgressPrecent,
	setActiveUncs,
	setAnalogUncList,
	setAssistStatus,
	setDataImportMagazine,
	setLoadingStates,
	setOpenModalImportMagazine,
	setUncCells,
	setProgressPrecent,
	setUserProjects,
	loadingAddUnc,
} from '@root-gipro/modules/userProjects/store/actions'
import { fetchUncs as fetchUncsForUpdate } from '@root-gipro/modules/userProjects/store/actions'

import store from '@root-gipro/store'
import { fetchData, fetchHeaders, userProjectsApi } from '@root-gipro/store/api'
import produce from 'immer'
import { ReactText } from 'react'
import { push } from 'react-router-redux'
import { call, cancel, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	saveUserProject as saveUserProjectAction,
	addUserProjects,
	loadingT7Data,
	loadingUserProjects,
	setAllowMoreUserProjects,
	setExportable,
	setMassErrors,
	setUserProjectInList,
	setUserProjectsPaginationCount,
} from './actions'
import { AdminPanelType } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import QueryString from 'qs'
import { IUncMain } from '@root-gipro/store/interfaces'
import { eventChannel } from 'redux-saga'
import { setFetchFailure } from '@root-gipro/store/actions'

const limit = 5

//Сага загрузки/обновления проекта
function* fetchUserProject(action: { type: typeof UserProjectsType.FETCH_USER_PROJECT; id: string }) {
	yield put(setFetchFailure(false))
	const projectFetch: IUserProject = yield call(fetchData, `/user-projects/${action.id}`, (res: any) => res.userProject)
	if (projectFetch) {
		const project = { ...projectFetch, locked: !!projectFetch.locked }
		const clone = Object.assign({}, project)
		delete clone.id
		delete clone.userId
		delete clone.author

		yield put({ type: UserProjectsType.CHOOSE_PROJECT, project })
		yield put({ type: UserProjectsType.SET_ORIGINAL_OBJ, project })

		yield put(saveUserProjectAction(clone, false, project.id, false))
	} else {
		yield put(setFetchFailure(true))
	}
}

function* updateProgressDuringRequest(expectedTime: number) {
	let progress = 0
	const timeStepFast = expectedTime / 80
	const timeStepSlow = (expectedTime / 80) * 2

	try {
		while (progress < 70) {
			yield delay(timeStepFast * 1000)
			progress += 1
			yield put(setProgressPrecent(progress))
		}

		while (progress < 95) {
			yield delay(timeStepSlow * 1000)
			progress += 1
			yield put(setProgressPrecent(progress))
		}

		yield delay(Infinity)
	} catch {
		yield put(setProgressPrecent(100))
	}
}
//Сага блокирование проекта из списка проектов
function* updateStatusUserProject({
	locked,
	id,
}: {
	type: typeof UserProjectsType.UPDATE_STATUS_USER_PROJECT
	locked: boolean
	id: string
}) {
	let project: IRequestProject

	try {
		project = yield call(fetchData, `/user-projects/${id}`, (res: any) => res, 'PATCH', JSON.stringify({ locked }))

		if (project && project.userProject) {
			const userProject: IUserProject = project.userProject

			yield put({ type: UserProjectsType.CHOOSE_PROJECT, project: userProject })
			yield put({ type: UserProjectsType.SET_ORIGINAL_OBJ, project: userProject })

			const currentProject: IUserProject = yield call(fetchData, `/user-projects/${id}`, (res: any) => res.userProject)

			yield put(setUserProjectInList(currentProject))

			yield put(
				showNotify({
					type: 'success',
					message: `Проект ${userProject.code} ${locked ? 'заблокирован' : 'разблокирован'}`,
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага сохраненеия проекта
function* saveUserProject({
	body,
	isNew,
	id,
	showAlert = true,
}: {
	type: typeof UserProjectsType.SAVE_USER_PROJECT
	body: IUserProject
	isNew: boolean
	id: ReactText
	showAlert: boolean
}) {
	const copy: IUserProject = Object.assign({}, body)

	delete copy.sumUncCost
	delete copy.userId
	delete copy.sumUncCostWithNds
	delete copy.totalInvestmentUncOffer
	delete copy.t7Id
	delete copy.t7Version
	delete copy.versions

	let project: IRequestProject

	if (!(copy.code && copy.name && copy.years)) {
		yield put(
			showNotify({
				type: 'warning',
				message: 'Не заполнены обязательные поля',
			})
		)
		return
	}

	try {
		if (isNew && !copy.unc_sort) {
			throw Error('validatonFailed')
		} else if (isNew && copy.unc_sort) {
			project = yield call(fetchData, '/user-projects', (res: any) => res, 'POST', JSON.stringify(copy))
		} else {
			project = yield call(fetchData, `/user-projects/${id}`, (res: any) => res, 'PATCH', JSON.stringify(copy))
		}

		if (project && project.userProject) {
			let userProject: IUserProject = project.userProject
			userProject = { ...userProject, locked: !!userProject.locked }

			yield put({ type: UserProjectsType.CHOOSE_PROJECT, project: userProject })
			yield put({ type: UserProjectsType.SET_ORIGINAL_OBJ, project: userProject })

			if (isNew) {
				yield put(
					showNotify({
						type: 'success',
						message: `Создан новый проект ${userProject.code}`,
					})
				)

				yield put(push(`/user-projects/project/${userProject.id}/parameters`))
				yield put({ type: 'GET_T7_DATA', id: userProject.id })
			} else {
				const delayTime = 2000
				if (showAlert) {
					yield put(
						showNotify({
							type: 'success',
							message: `Проект ${userProject.code} успешно сохранен`,
							duration: delayTime,
						})
					)
					yield delay(delayTime)
				}
			}

			if (project.alert) {
				const alert = project.alert
				yield put(
					showNotify({
						type: alert.type,
						message: alert.message,
					})
				)
			}
		}
	} catch (error) {
		if (error.message === 'validatonFailed') {
			yield put(
				showNotify({
					type: 'warning',
					message: `Выберите режим унц 2019/2024`,
				})
			)
		} else {
			console.log(error)
		}
	}
}

function* deleteUserProject({
	id,
	name,
}: {
	type: typeof UserProjectsType.DELETE_USER_PROJECT
	name: string
	id: string
}) {
	let { versions } = store.getState().userProjects.projectObj
	versions = versions?.filter(version => version.userProjectId !== Number(id))

	const res: Omit<IRequestProject, 'userProject'> = yield call(
		fetchData,
		`/user-projects/${id}`,
		(res: any) => res,
		'DELETE'
	)

	if (res.status === 'success') {
		yield put(
			showNotify({
				type: 'success',
				message: `Версия расчета ${name} успешно удалена!`,
			})
		)
		// yield put(createProjectTemplate())
		console.log(versions)
		if (versions && versions?.length > 0) {
			yield put(push(`/user-projects/project/${versions[0].userProjectId}/parameters`))
		} else {
			yield put(push(`/user-projects`))
		}
	}
}

function* uncEdit(params: { type: typeof UserProjectsType.UNC_POSITION_EDIT; params: any }) {
	// DroppableProps
	yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })
	const userProjectId = store.getState().userProjects.projectObj.id
	const position = params.params.destination.index + 1

	// const params = {
	// 	params: {
	// 		draggableId: uncId,
	// 		destination: { index: 0, droppableId: 'tbody 1' },
	// 		source: { index: 0, droppableId: 'tbody 1' },
	// 	},
	// }

	const table = params.params.destination.droppableId
	const id = params.params.draggableId
	const numericValue = parseInt(table.match(/\d+/)[0], 10)

	if (numericValue)
		if (table.includes('tableTabs')) {
			try {
				yield call(
					fetchData,
					`/user-uncs/${id}`,
					(res: any) => res.userUnc,
					'PATCH',
					JSON.stringify({ tableNum: numericValue })
				)

				yield put({ type: 'FETCH_UNCS', id: userProjectId })
			} catch (error) {
				console.log(error)
			}
		} else {
			try {
				yield call(
					fetchData,
					`/user-uncs/${id}`,
					(res: any) => res.userUnc,
					'PATCH',
					JSON.stringify({ position, table: numericValue })
				)

				yield put({ type: 'FETCH_UNCS', id: userProjectId })
			} catch (error) {
				console.log(error)
			}
		}
	yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
}

function* addUnc({
	tableNum,
	uncCellId,
	count,
	voltage,
}: {
	type: typeof UserProjectsType.ADD_UNC
	tableNum: ReactText
	uncCellId: ReactText
	count: number
	addBuffer: boolean
	voltage?: number
}) {
	try {
		yield put(loadingAddUnc(true))
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		tableNum = Number(tableNum) !== 6 ? tableNum : '1'

		const body: any = {
			tableNum,
			uncCellId,
			count,
			voltage,
		}
		if (project.regionIds && project.regionIds.length > 0) body.regionId = project.regionIds[0]
		const data: IUserProject = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const newElem: unknown = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)

			const userProjects = store.getState().userProjects
			const buffer = produce(userProjects, draft => {
				draft.uncAddBuffer.push(newElem as IUncAddBuffer)
			})

			yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	} finally {
		yield put(loadingAddUnc(false))
	}
}

function* createUncsB1({
	uncMainId,
	uncs,
	interpolated,
	tableNum,
}: {
	type: typeof UserProjectsType.CREATE_UNCS_B1
	uncMainId: number
	uncs: { [key: string]: IUnc[] }
	interpolated: number
	tableNum: ReactText
}) {
	try {
		for (const key in uncs) {
			const totalSum = uncs[key].reduce((accum: number, curr: IUnc) => (accum += curr.totalCost), 0)
			const costSum = totalSum / 1000
			yield put({
				type: UserProjectsType.CREATE_UNC_B1,
				uncMainId,
				regionId: key,
				interpolated,
				tableNum,
				count: costSum,
			})
		}
	} catch (err) {
		console.log(err)
	}
}

function* createUncB1({
	uncMainId,
	uncCellId,
	regionId,
	interpolated,
	tableNum,
	count,
}: {
	type: typeof UserProjectsType.CREATE_UNC_B1
	uncMainId: number
	uncCellId: number
	regionId: ReactText
	interpolated: number
	tableNum: ReactText
	count: number
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		const body = {
			tableNum,
			uncMainId,
			regionId,
			interpolated,
			uncCellId,
		}

		const data: IUnc = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const uncElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}`,
				(res: any) => res.userUnc,
				'PATCH',
				JSON.stringify({ count })
			)

			const newElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)

			if (uncElem && newElem) {
				const clone = store.getState().userProjects.uncs.slice()
				clone.push(newElem)
				clone.sort((a, b) => a.tableNum - b.tableNum)
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone })
				yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
			}
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}

function* createSpecialUnc({
	uncMainId,
	regionId,
	interpolated,
	tableNum,
}: {
	type: typeof UserProjectsType.CREATE_SPECIAL_UNC
	uncMainId: ReactText
	regionId: ReactText
	interpolated: { [key: string]: ReactText }
	tableNum: ReactText
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')
		tableNum = Number(tableNum) !== 6 ? tableNum : '1'
		const body: any = {
			tableNum,
			uncMainId,
			regionId,
			interpolated,
		}

		body.uncCellId = null
		const data: IUnc = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const newElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)
			// if (newElem && newElem.interpolated) {
			// let newElemWithCount = { ...newElem, count: newElem.interpolated['interpolated'] }

			const userProjects = store.getState().userProjects
			const buffer = produce(userProjects, draft => {
				draft.uncAddBuffer.push(newElem as IUncAddBuffer)
			})
			yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })

			yield put({
				type: 'CHANGE_UNC_COUNTER',
				id: newElem.id,
				count: 0,
				inBuffer: true,
			})
		}
		// }
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}

//Удаление унца
function* deleteUnc({ id, inBuffer }: { type: typeof UserProjectsType.DELETE_UNC; id: ReactText; inBuffer?: boolean }) {
	try {
		yield put({ type: 'DELETE_UNC_STATUS', isDeleted: false })
		yield put({ type: 'FETCH_REQUEST' })
		const res: Omit<IRequestProject, 'userProject'> = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res,
			'DELETE'
		)

		if (res.status === 'success') {
			let projectId
			if (inBuffer) {
				const userProjects = store.getState().userProjects
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				projectId = userProjects.uncAddBuffer[ind].userProjectId
				const buffer = produce(userProjects, draft => {
					draft.uncAddBuffer.splice(
						draft.uncAddBuffer.findIndex(item => item.id === id),
						1
					)
				})
				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
			} else {
				const userProjects = store.getState().userProjects
				const uncs = store.getState().userProjects.uncs
				const ind = uncs.findIndex((elem: any) => elem.id === id)
				projectId = uncs[ind].userProjectId
				const clone = produce(userProjects, draft => {
					draft.uncs.splice(
						draft.uncs.findIndex(item => item.id === id),
						1
					)
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			}

			yield put({ type: 'FETCH_USER_PROJECT', id: projectId })
		}
		yield put({ type: 'DELETE_UNC_STATUS', isDeleted: true })
	} catch (error) {
		yield put({ type: 'DELETE_UNC_STATUS', isDeleted: true })
		console.log(error)
	}
}

function* replaceUnc({
	oldUncId,
	newUncId,
}: {
	type: typeof UserProjectsType.REPLACE_UNC
	oldUncId: string
	newUncId: string
}) {
	try {
		const res: any = yield call(
			fetchData,
			`/user-uncs/${oldUncId}/setAnalog`,
			(res: any) => res,
			'PATCH',
			JSON.stringify({ uncCellId: newUncId })
		)

		const userProjects = store.getState().userProjects

		yield put({ type: UserProjectsType.FETCH_UNCS, id: res.userUnc.userProjectId })

		yield put({ type: 'FETCH_USER_PROJECT', id: userProjects.originalObj.id })
	} catch (error) {
		console.log(error)
	}
}

function* genAnalogUnc({ id }: { type: typeof UserProjectsType.GET_ANALOG_UNC; id: ReactText }) {
	try {
		const res: any = yield call(fetchData, `/uncCells/${id}/getAnalogs/`, (res: any) => res, 'GET')

		if (res.status === 'success') {
			yield put(setAnalogUncList(res.uncCells))
		}
	} catch (error) {
		console.log(error)
	}
}
function* fetchAssistStatus() {
	try {
		const userId = store.getState().auth.user.id

		const res: any = yield call(fetchData, `/user-settings?userId=${userId}`, (res: any) => res, 'GET')

		if (res.status === 'success' && res?.userSettings[0]) {
			yield put(setAssistStatus(res?.userSettings[0]?.enableUncAssist))
		}
	} catch (error) {
		console.log(error)
	}
}
function* changeAssistStatus(changes: any) {
	try {
		const userId = store.getState().auth.user.id

		const res: any = yield call(
			fetchData,
			`/user-settings?userId=${userId}`,
			(res: any) => res,
			'POST',
			JSON.stringify({ enableUncAssist: changes?.enableUncAssist })
		)
		if (res.includes('update')) {
			const jsonString = res.replace('update', '')
			const parsedData = JSON.parse(jsonString)
			if (parsedData?.status === 'success') {
				yield put(getAssistStatus())
			}
		} else if (res.status === 'success') {
			yield put(getAssistStatus())
		}
	} catch (error) {
		console.log(error)
	}
}

function* changeRegionFactor({ id, uncTableId, regionId }: any) {
	// try {
	// 	const region = yield call(
	// 		fetchData,
	// 		`/region-factors/search?uncTableId=${uncTableId}&regionId=${regionId}`,
	// 		(res: any) => res.regionFactor
	// 	)
	// 	const res = yield call(fetchData, `/uncs/${id}`, (res: any) => res.unc, 'PATCH', JSON.stringify({ regionId }))
	// 	let uncTable = store.getState().formTwenty.uncTable,
	// 		ind = searchByKey(uncTable, 'id', id)
	// 	let clone = uncTable.slice()
	// 	res.regionFactor = region
	// 	res.uncTable = clone[ind].uncTable
	// 	clone.splice(ind, 1, res)
	// 	yield put({ type: 'UPDATE_UNC_TABLE', uncTable: clone })
	// } catch (error) {
	// 	console.log(error)
	// }
}

function* changeFormTwentyName({ name }: any) {
	// try {
	// 	const id = store.getState().formTwenty.id
	// 	yield call(fetchData, `/projects/${id}`, res => res, 'PATCH', JSON.stringify({ name }))
	// } catch (error) {
	// 	console.log(error)
	// }
}

//Сага загрузки проектов
function* getMyProjects() {
	try {
		const projects: Omit<IAllRequestProject, 'userProject'> = yield call(fetchData, '/user-projects', (res: any) => res)

		if (projects) {
			yield put({
				type: 'SET_USER_PROJECTS',
				projects: projects.userProjects,
				userProjectsCnt: projects.userProjectsCnt,
				totalInvestmentUncOfferSum: Number(projects.totalInvestmentUncOfferSum),
			})
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы унцов проекта
function* fetchUncs({ id }: { type: typeof UserProjectsType.FETCH_UNCS; id: string }) {
	try {
		yield put(setFetchFailure(false))
		const uncs: IUnc = yield call(
			fetchData,
			`/user-projects/${id}/user-uncs?with=uncMain,uncCell,regionFactor`,
			(res: any) => res.userUncs
		)
		if (uncs) {
			yield put({ type: 'SET_PROJECT_UNCS', uncs })
		}
	} catch (error) {
		console.log(error)
		yield put(setFetchFailure(true))
	}
}

//Сага загрузки таблицы унцов проекта
function* fetchNonStandardizedCosts() {
	try {
		const nonStandardizedCosts: IUnnormalizedListItem[] = yield call(
			fetchData,
			`/unnormalizedList`,
			(res: any) => res.unnormalizedList
		)
		if (nonStandardizedCosts) {
			yield put({ type: 'SET_NON_STANDARDIZED_COSTS', non_standardized_costs: nonStandardizedCosts })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы унцов для добавления в проект
function* fetchUncTableData({
	id,
	unc_sort,
}: {
	type: typeof UserProjectsType.FETCH_UNC_TABLE_DATA
	id: number
	unc_sort: string
}) {
	try {
		const uncTable: IUncTableRequest = yield call(
			fetchData,
			`/unc-mains/${id}?with=uncTable`,
			(res: any) => res[`uncMain`]
		)
		if (uncTable) {
			yield put({ type: 'SET_UNC_TABLE_DATA', uncTableData: uncTable.uncTable })
		}
	} catch (error) {
		console.log(error)
	}
}

// Сага изменения комментария расценки
function* changeUncComment({
	id,
	objectName,
	stage,
	kf,
	description,
	comment,
	complicating_params,
	kfConfirm,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_COMMENT
	id: ReactText
	objectName: string
	stage: string
	kf: any
	description: string
	comment: string
	complicating_params: any
	kfConfirm?: boolean | number
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({
				objectName,
				stage,
				...kf,
				description,
				comment,
				complicating_params,
				kf_confirmed: kfConfirm ? 1 : 0,
			})
		)

		if (uncElem) {
			yield put(fetchUncsForUpdate(uncElem.userProjectId as string))
			const userProjects = store.getState().userProjects
			const ind = userProjects.uncs.findIndex(elem => elem.id === id)
			const indActiveUncs = userProjects.activeUncs.findIndex(elem => elem.id === id)
			const cloneUnc = produce(userProjects, draft => {
				draft.uncs[ind].objectName = uncElem.objectName
				draft.uncs[ind].stage = uncElem.stage
				draft.uncs[ind].kf = uncElem.kf
				draft.uncs[ind].description = uncElem.description
				draft.uncs[ind].comment = uncElem.comment
				draft.uncs[ind].totalCost = uncElem.totalCost
				draft.uncs[ind].complicating_params = uncElem.complicating_params
			})
			const cloneActiveUncs = produce(userProjects, draft => {
				draft.activeUncs[indActiveUncs].objectName = uncElem?.objectName
				draft.activeUncs[indActiveUncs].stage = uncElem.stage
				draft.activeUncs[indActiveUncs].kf = uncElem.kf
				draft.activeUncs[indActiveUncs].description = uncElem.description
				draft.activeUncs[indActiveUncs].comment = uncElem.comment
				draft.uncs[ind].totalCost = uncElem.totalCost
				draft.uncs[ind].complicating_params = uncElem.complicating_params
			})
			yield put(setActiveUncs([cloneActiveUncs.activeUncs[indActiveUncs]]))
			yield put({ type: 'SET_PROJECT_UNCS', uncs: cloneUnc.uncs })
		}
	} catch (error) {
		console.log(error)
	}
}
// Сага изменения комментария расценки
function* changeUncCfArchitecture({
	id,
	complicating_params,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_CF_ARCHITECTURE
	id: ReactText
	complicating_params: any
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ complicating_params })
		)

		if (uncElem) {
			yield put(fetchUncsForUpdate(uncElem.userProjectId as string))
			const userProjects = store.getState().userProjects
			const ind = userProjects.uncs.findIndex(elem => elem.id === id)
			const indActiveUncs = userProjects.activeUncs.findIndex(elem => elem.id === id)
			const cloneUnc = produce(userProjects, draft => {
				draft.uncs[ind].objectName = uncElem.objectName
				draft.uncs[ind].stage = uncElem.stage
				draft.uncs[ind].kf = uncElem.kf
				draft.uncs[ind].description = uncElem.description
				draft.uncs[ind].comment = uncElem.comment
				draft.uncs[ind].totalCost = uncElem.totalCost
				draft.uncs[ind].complicating_params = uncElem.complicating_params
			})
			yield put({ type: 'SET_PROJECT_UNCS', uncs: cloneUnc.uncs })
		}
	} catch (error) {
		console.log(error)
	}
}
//Сага изменения количества единиц расценки
function* changeUncCounter({
	id,
	count,
	inBuffer,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_COUNTER
	id: ReactText
	count: string
	inBuffer?: boolean | undefined
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ count })
		)
		if (uncElem) {
			const userProjects = store.getState().userProjects

			if (inBuffer) {
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				const copyBuffer = produce(userProjects, draft => {
					if (draft.uncAddBuffer[ind]) draft.uncAddBuffer[ind] = Object.assign(draft.uncAddBuffer[ind], uncElem)
				})
				const clone = produce(userProjects, draft => {
					const unc = draft.uncs.find(unc => unc.id === uncElem.id)

					if (unc) {
						unc.count = uncElem.count
						unc.totalCost = uncElem.totalCost
					}
				})
				// yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: copyBuffer.uncAddBuffer })
			} else {
				const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
				const clone = produce(userProjects, draft => {
					const unc = draft.uncs.find(unc => unc.id === uncElem.id)

					if (unc) {
						unc.count = uncElem.count
						unc.totalCost = uncElem.totalCost
					}
				})
				yield put({ type: 'SET_ACTIVE_UNCS', uncs: clone.uncs })
				yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
			}
			yield put({ type: 'FETCH_UNCS', id: uncElem.userProjectId })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага изменения региона расценки
function* changeUncRegion({
	id,
	regionId,
	zoneId,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_REGION
	id: ReactText
	regionId: ReactText
	zoneId?: ReactText
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}?with=regionFactor`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ regionId, region_zone_id: zoneId })
		)
		if (uncElem) {
			const userProjects = store.getState().userProjects
			const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
			const clone = produce(userProjects, draft => {
				draft.uncs[ind] = Object.assign(draft.uncs[ind], uncElem)
			})
			yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага изменения напряжения расценки
function* changeUncVoltage({
	id,
	voltage,
	inBuffer,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_VOLTAGE
	id: string
	voltage: number
	inBuffer?: boolean
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ voltage })
		)
		if (uncElem) {
			const userProjects = store.getState().userProjects

			if (inBuffer) {
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				const copyBuffer = produce(userProjects, draft => {
					draft.uncAddBuffer[ind] = Object.assign(draft.uncAddBuffer[ind], uncElem)
				})

				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: copyBuffer.uncAddBuffer })
			} else {
				const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
				const clone = produce(userProjects, draft => {
					draft.uncs[ind] = Object.assign(draft.uncs[ind], uncElem)
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			}
		}
	} catch (error) {
		console.log(error)
	}
}
function* handleUncCells() {
	try {
		const uncCells: IUncMain[] = yield call(fetchData, '/uncCells?unc_sort=2024', (res: any) => res.uncCells)
		yield put(setUncCells(uncCells))
	} catch (error) {
		console.log(error)
	}
}
function* selectVoltage({
	uncCellId,
	uncId,
}: {
	type: typeof UserProjectsType.SELECT_VOLTAGE
	uncCellId: ReactText
	uncId: ReactText
}) {
	try {
		const uncFullInfo = store.getState().userProjects.uncFullInfo
		const voltage: IUncCell = yield call(
			fetchData,
			`/uncCells/${uncCellId}?with=voltageValues,uncCellAssist`,
			(res: any) => res.uncCell
		)
		if (!uncFullInfo) {
			yield put({
				type: 'SET_UNC_FULL_INFO',
				voltage,
			})
		}
		yield put({
			type: 'SET_VOLTAGE',
			uncId,
			targetVoltageValues: voltage.voltageValues,
		})
		yield put({
			type: 'GET_UNC_CELLS',
		})
		yield put({ type: 'SET_VOLTAGE_ID', uncId, targetVoltageId: uncId })
	} catch (error) {
		console.log(error)
	}
}

function* updateDeflators({
	start,
	end,
}: {
	type: typeof UserProjectsType.UPDATE_DEFLATORS
	start: ReactText
	end: ReactText
}) {
	try {
		const deflators: { [key: string]: string } = yield call(
			fetchData,
			`/info/deflators?year.min=${start}&year.max=${end}`,
			(res: any) => res.deflators
		)

		if (deflators) {
			const originalObj: any = Object.assign({}, store.getState().userProjects.originalObj),
				editObj = Object.assign({}, store.getState().userProjects.projectObj)
			const years: any = {}
			for (let i = +start; i <= +end; i++) {
				years[i] = {
					offer: null,
					deflator: null,
				}
				if (originalObj[i]) {
					years[i].offer = originalObj[i].offer
					years[i].deflator = originalObj[i].deflator
				} else {
					years[i].offer = null
					years[i].deflator = deflators[i]
				}
			}
			editObj.years = years
			yield put({ type: 'CHOOSE_PROJECT', project: editObj })
		}
	} catch (error) {
		console.log(error)
	}
}

function* getAllDeflators() {
	try {
		yield put({ type: AdminPanelType.LOADING_SETTINGS, loading: true })
		const { deflators, precision }: { deflators: { year: number; value: string }[]; precision: number } = yield call(
			fetchData,
			`/deflator`,
			(res: any) => res
		)

		yield put({ type: UserProjectsType.SET_DEFLATORS, deflators: deflators, precision })
	} catch (error) {
		console.log(error)
	} finally {
		yield put({ type: AdminPanelType.LOADING_SETTINGS, loading: false })
	}
}

function* updateDeflatorsValue({
	deflators,
}: {
	type: typeof UserProjectsType.UPDATE_DEFLATORS_VALUE
	deflators: any
}) {
	try {
		yield call(fetchData, `/deflator`, (res: any) => res, 'POST', JSON.stringify({ ...deflators }))

		yield put(
			showNotify({
				type: 'success',
				message: `Индексы-дефляторы обновлены!`,
			})
		)
	} catch (error) {
		console.log(error)
	}
}

function* getCheckedErrors({
	id,
	sectionIdsArr,
}: {
	type: typeof UserProjectsType.GET_CHECKED_ERRORS
	id: ReactText | undefined
	sectionIdsArr: number[]
}) {
	try {
		const errors: ICheckedErrors = yield call(
			fetchData,
			`/user-projects/${id}/rule-error?with=messages`,
			(res: any) => res.userRuleError,
			'POST',
			JSON.stringify({ sectionIds: sectionIdsArr })
		)

		if (errors) {
			yield put({
				type: 'SET_PROJECT_ERRORS',
				errors: errors.messages,
			})
		} else {
			yield put({ type: 'SET_PROJECT_ERRORS', errors: '0' })
		}

		yield put({ type: 'SHOW_PROJECT_ERRORS', show: true })
	} catch (error) {
		console.log(error)
	}
}
function* autoAddUnc({
	id,
	sectionIdsArr,
}: {
	type: typeof UserProjectsType.AUTO_ADD_UNC
	id: ReactText | undefined
	sectionIdsArr: number[]
}) {
	try {
		const errors: ICheckedErrors = yield call(
			fetchData,
			`/user-projects/${id}/autoAddUnc`,
			(res: any) => res.userRuleError,
			'POST',
			JSON.stringify({ sectionIds: sectionIdsArr })
		)

		if (errors) {
			yield put({
				type: 'SET_PROJECT_ERRORS',
				errors: errors.messages,
			})
		} else {
			yield put({ type: 'SET_PROJECT_ERRORS', errors: '0' })
		}

		yield put({ type: 'SHOW_PROJECT_ERRORS', show: true })
	} catch (error) {
		console.log(error)
	}
}

function* downloadRulesCheck() {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/rules`, {
			method: 'GET',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = 'Правила проверки.xlsx'
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}
function* downloadImportLog({ linked }: { type: typeof UserProjectsType.SET_DOWNLOAD_IMPORT_DATA; linked: string }) {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}${linked}`, {
			method: 'GET',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = 'ImportData.xlsx'
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

function* uploaderRulesCheck(file: any) {
	try {
		const target = file.file[0]
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(target.type)) {
			yield put(showNotify({ type: 'warning', message: `Неверный формат файла! Допустимый формат - *.xlsx` }))
			return
		}

		const data = new FormData()
		data.append('file', target, target.name)

		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/rules`, {
			method: 'POST',
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(response => response.json())
			.then(success => {
				if (success.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: 'Ошибка загрузки файла, проверьте файл или попробуйте позже.',
						})
					)
				} else {
					store.dispatch(
						showNotify({
							type: 'success',
							message: 'Файл успешно загружен!',
						})
					)
				}
			})
			.catch(error => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})
	} catch (error) {
		yield put(
			showNotify({
				type: 'error',
				message: 'Ошибка загрузки файла, попробуйте позже.',
			})
		)
	}
}

function* massСheck({ sectionIdsArr }: { type: typeof UserProjectsType.MASS_CHECK; sectionIdsArr: number[] }) {
	const { userProjects } = store.getState().filter
	const { sort, projectList } = store.getState().userProjects

	let params = { order: (sort ? '' : '-') + sort.sortName, limit: projectList.length }
	const object = excludeData(userProjects.showFilterState)
	const intermediateValue = {
		...object,
	}
	if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}

	const paramsString = Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&')

	try {
		yield put({ type: 'MASS_CHECK_LOADER', loading: true })

		const response = yield fetch(
			`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/rule-error-many?${paramsString}&with=messages`,
			{
				method: 'POST',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('access_token')}`,
				},
				body: JSON.stringify({ sectionIds: sectionIdsArr }),
			}
		)

		if (!response.ok) {
			yield put(
				showNotify({
					type: 'error',
					message: 'Произошла ошибка формирования отчета',
				})
			)
		}

		if (response.headers.get('content-type')?.includes('application/json')) {
			const errors = yield response.json()

			if (errors) {
				const prepared = errors.map(({ userProjectCode, messages, id }: ICheckedMassErrors) => ({
					userProjectCode,
					messages,
					id,
				}))

				yield put(setMassErrors(prepared))
				yield put({ type: 'MASS_CHECK_LOADER', loading: false })
			} else {
				yield put(setMassErrors(null))
			}
		} else {
			const reader = response.body.getReader()
			const decoder = new TextDecoder('utf-8')
			let buffer = ''
			let finalJsonBuffer = ''
			let doneReading = false

			while (!doneReading) {
				const { done, value } = yield reader.read()

				if (done) {
					doneReading = true
				}

				buffer += decoder.decode(value, { stream: true })
				let boundary = buffer.indexOf('\n')

				while (boundary !== -1) {
					const chunk = buffer.slice(0, boundary).trim()
					buffer = buffer.slice(boundary + 1)

					if (chunk.startsWith('data:')) {
						const dataValue = chunk.replace('data:', '').trim()

						const progressPercent = parseInt(dataValue)
						if (!isNaN(progressPercent)) {
							yield put(setProgressPrecent(progressPercent))
						}
					} else if (chunk) {
						finalJsonBuffer = chunk
					}
					boundary = buffer.indexOf('\n')
				}

				if (done) {
					if (buffer.trim()) {
						finalJsonBuffer = buffer.trim()
					}

					if (finalJsonBuffer) {
						const errors = JSON.parse(finalJsonBuffer)

						if (errors) {
							const prepared = errors?.userRuleError?.map(({ userProjectCode, messages, id }: ICheckedMassErrors) => ({
								userProjectCode,
								messages,
								id,
							}))

							yield put(setMassErrors(prepared))
						} else {
							yield put(setMassErrors(null))
						}
					}
				}
			}
		}
	} catch (error) {
		console.log(error)
		yield put(
			showNotify({
				type: 'error',
				message: 'Произошла ошибка при массовой проверке.',
			})
		)
	} finally {
		yield put({ type: 'MASS_CHECK_LOADER', loading: false })
		yield put(setProgressPrecent(0))
	}
}

function* downloadForm2({ id, name }: { type: typeof UserProjectsType.DOWNLOAD_F2; id: ReactText; name: string }) {
	const url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/forma2`

	const { userProjects } = store.getState().filter
	const { sort, projectList } = store.getState().userProjects

	let params = { order: (sort ? '' : '-') + sort.sortName, limit: projectList.length }
	const object = excludeData(userProjects.showFilterState)
	const intermediateValue = {
		...object,
	}
	if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}

	const paramsString = Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&')

	try {
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })
		const avg_time_per_project = 0.10588235
		const expected_time = avg_time_per_project * Number(userProjects.countResult)
		const progressTask = yield fork(updateProgressDuringRequest, expected_time)
		yield call(() =>
			fetch(url + '?' + paramsString, {
				method: 'GET',
				headers: fetchHeaders(),
			})
				.then(res => res.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob),
						link = document.createElement('a')
					document.body.appendChild(link)
					link.href = url
					link.download = `Форма 2.xlsx`
					link.click()
					link.remove()
				})
		)
		yield cancel(progressTask)
		yield put(setProgressPrecent(100))
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
	} catch (error) {
		console.log(error)
	} finally {
		yield put(setProgressPrecent(0))
	}
}

function* uploadForm2({
	file,
	importInto,
	uncSort,
	companyId,
}: {
	type: typeof UserProjectsType.UPLOAD_F2
	file: any
	importInto: string
	uncSort: string
	companyId: string
}) {
	try {
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(file[0].type)) {
			yield put(
				showNotify({
					type: 'warning',
					message: 'Неверный формат файла! Допустимый формат - *.xlsx',
				})
			)
			return
		}

		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })

		const data = new FormData()
		data.append('file', file[0], file[0].name)

		const response = yield fetch(
			`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/uploadForm2?importInto=${importInto}&uncSort=${uncSort}&companyId=${companyId}`,
			{
				method: 'POST',
				body: data,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('access_token')}`,
				},
			}
		)

		if (!response.ok) {
			yield put(
				showNotify({
					type: 'error',
					message: 'Ошибка загрузки файла, попробуйте позже.',
				})
			)
			return
		}

		// Проверка: Если данные возвращаются как JSON-объект
		if (response.headers.get('content-type')?.includes('application/json')) {
			const success = yield response.json()

			if (success.status === 'failure') {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
				store.dispatch(
					showNotify({
						type: 'error',
						message: success.message,
					})
				)
			} else {
				store.dispatch(setDataImportMagazine(success.done.errors, success.done.reportUrl))
				if (success.done.errors.length > 0) store.dispatch(setOpenModalImportMagazine(true))

				store.dispatch(
					showNotify({
						type: 'success',
						message:
							'Файл успешно загружен!' +
							' Добавлено проектов: ' +
							success.done.added +
							' Обновлено проектов: ' +
							success.done.updated,
					})
				)
				if (success.done.errors.length > 0) {
					store.dispatch(
						showNotify({
							type: 'error',
							message: success.done.errors.map((item: string) => item).join(', '),
						})
					)
				}
			}
		} else {
			const reader = response.body.getReader()
			const decoder = new TextDecoder('utf-8')
			let buffer = ''
			let finalJsonBuffer = ''

			while (true) {
				const { done, value } = yield reader.read()
				buffer += decoder.decode(value, { stream: true })
				let boundary = buffer.indexOf('\n')

				while (boundary !== -1) {
					const chunk = buffer.slice(0, boundary).trim()
					buffer = buffer.slice(boundary + 1)

					// Если идут данные загрузки
					if (chunk.startsWith('data:')) {
						const progressPercent = parseInt(chunk.replace('data:', '').trim())
						if (!isNaN(progressPercent)) {
							store.dispatch(setProgressPrecent(progressPercent))
						}
					} else if (chunk) {
						// собираем финальные данные
						finalJsonBuffer = chunk
					}
					boundary = buffer.indexOf('\n')
				}

				// когда поток завершен, проверяем оставшийся буфер
				if (done) {
					if (buffer.trim()) {
						finalJsonBuffer = buffer.trim()
					}
					if (finalJsonBuffer) {
						try {
							const parsedData = JSON.parse(finalJsonBuffer)
							if (parsedData.status === 'failure') {
								// Обрабатываем ошибку, если в message есть текст ошибки
								store.dispatch(
									showNotify({
										type: 'error',
										message: `Ошибка: ${parsedData.message}`,
									})
								)
							} else if (parsedData.done && parsedData.done.errors) {
								const { errors, reportUrl, updated, added } = parsedData.done
								if (errors?.Error?.length || errors?.Notice?.length) {
									store.dispatch(setDataImportMagazine(errors, reportUrl))
									store.dispatch(setOpenModalImportMagazine(true))
								}
								store.dispatch(
									showNotify({
										type: 'success',
										message:
											'Файл успешно загружен!' + ' Добавлено проектов: ' + updated + ' Обновлено проектов: ' + added,
									})
								)
							}
						} catch (e) {
							console.error('Ошибка парсинга: ', e)
						}
					} else {
						console.error('Ошибка сборки или получения данных')
					}
					yield put(setProgressPrecent(0))
					break
				}
			}
		}

		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
	} catch (error) {
		console.error(error)
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
		store.dispatch(
			showNotify({
				type: 'error',
				message: 'Ошибка обработки файла, попробуйте позже.',
			})
		)
	}
}

function* downloadForm20({
	id,
	name,
	version,
	formYear,
}: {
	type: typeof UserProjectsType.DOWNLOAD_F20
	id: ReactText
	name: string
	version: string
	formYear?: ReactText
}) {
	let url = ''
	switch (version) {
		case 'base':
			// Старая Форма20 с одним проектом
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/f20`
			break
		case 'new':
			// Новая Форма20 с одним проектом
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/newf20`
			break
		case 'all':
			// Новая Форма20 со всеми проектами
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/forma20`
			break
		default:
			url = ''
	}

	const { userProjects } = store.getState().filter
	const { company } = store.getState().public
	const { sort, projectList } = store.getState().userProjects

	let ogrn: any = ''
	if (userProjects.showFilterState.companyId) {
		const filterCompany = [...userProjects.showFilterState.companyId]
		if (filterCompany.length === 1) {
			const companyId = filterCompany[0]
			const currentOgrn = company.filter(el => el.id == companyId)[0].ogrn
			ogrn = currentOgrn ? `_${currentOgrn}` : ''
		}
	}

	let params = {
		order: (sort ? '' : '-') + sort.sortName,
		limit: projectList.length,
		unc_sort: formYear && formYear,
	}
	const object = excludeData(userProjects.showFilterState)
	const intermediateValue = {
		...object,
	}
	if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}
	const paramsString = Object.entries(params)
		.filter(([key, value]) => value !== undefined)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string | number | boolean)}`)
		.join('&')

	const f20_filename = (ogrn: string) => {
		const d = new Date()
		return 'H' + (d.getMonth() + 1) + d.getDate() + ogrn + '_20_0_0'
	}

	try {
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })

		const avg_time_per_project = 0.05053915
		const expected_time = avg_time_per_project * Number(userProjects.countResult)
		const progressTask = yield fork(updateProgressDuringRequest, expected_time)

		yield call(() =>
			fetch(url + '?' + paramsString, {
				method: 'GET',
				headers: fetchHeaders(),
			})
				.then(res => res.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob),
						link = document.createElement('a')
					document.body.appendChild(link)
					link.href = url
					link.download = f20_filename(ogrn) + '.xlsx'
					link.click()
					link.remove()
					setProgressPrecent(100)
				})
		)
		yield cancel(progressTask)
		yield put(setProgressPrecent(100))
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
	} catch (error) {
		console.log(error)
	} finally {
		yield put(setProgressPrecent(0))
	}
}

function* downloadForm20async() {
	const { userProjects } = store.getState().filter
	const { sort, projectList } = store.getState().userProjects

	let params = { order: (sort ? '' : '-') + sort.sortName, limit: projectList.length }
	const object = excludeData(userProjects.showFilterState)
	const intermediateValue = {
		...object,
	}
	if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}
	const paramsString = Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&')

	// Асинхронная генерация и выгрузка файла

	const url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/forma20async`
	let taskId = null

	try {
		yield fetch(url + '?' + paramsString, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(response => response.json())
			.then(success => {
				taskId = success.queued.taskId
			})
			.catch(error => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})
		yield put({ type: UserProjectsType.SET_F20_TASK, task: taskId })

		store.dispatch(
			showNotify({
				type: 'success',
				message: 'Форма 20 формируется. Ожидайте, загрузка начнется автоматически.',
			})
		)
	} catch (error) {
		console.log(error)
	}
}

function* downloadCheckWarns({
	sectionIdsArr,
}: {
	type: typeof UserProjectsType.DOWNLOAD_CHECK_WARNS
	sectionIdsArr: number[]
}) {
	const { userProjects } = store.getState().filter
	const { sort, projectList } = store.getState().userProjects

	let params = { order: (sort ? '' : '-') + sort.sortName, limit: projectList.length }
	const object = excludeData(userProjects.showFilterState)
	const intermediateValue = {
		...object,
	}
	if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}
	const paramsString = Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&')

	const url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/rule-error-many-xlsx?${paramsString}&with=messages`

	try {
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })

		const avg_time_per_project = 0.0430552
		const expected_time = avg_time_per_project * Number(userProjects.countResult)
		const progressTask = yield fork(updateProgressDuringRequest, expected_time)
		yield call(() =>
			fetch(url, {
				method: 'POST',
				headers: fetchHeaders(),
				body: JSON.stringify({ sectionIds: sectionIdsArr }),
			})
				.then(res => res.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob),
						link = document.createElement('a')
					document.body.appendChild(link)
					link.href = url
					link.download = `Проверка.xlsx`
					link.click()
					link.remove()
				})
		)
		yield cancel(progressTask)
		yield put(setProgressPrecent(100))
		yield put(setLoadingStates('', false))
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
	} catch (error) {
		console.log(error)
	} finally {
		yield put(setProgressPrecent(0))
	}
}

function* checkF20link({ task }: { type: typeof UserProjectsType.CHECK_F20_LINK; task: ReactText }) {
	try {
		const url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/fileExists?type=f20&name=${task}`

		let f20link = null

		yield fetch(url, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(response => response.json())
			.then(success => {
				f20link = success.done.fileUrl
			})
			.catch(error => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка запроса: ' + url,
					})
				)
			})

		if (f20link !== '') {
			yield put({ type: UserProjectsType.SET_F20_LINK, f20link: f20link })
			yield put({ type: UserProjectsType.SET_F20_TASK, task: null })
		}
	} catch (error) {
		console.log(error)
	}
}
function* uploadForm20({
	file,
	importInto,
	uncSort,
	companyId,
}: {
	type: typeof UserProjectsType.UPLOAD_F20
	file: any
	importInto: string
	uncSort: string
	companyId: string
}) {
	try {
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(file[0].type)) {
			yield put(
				showNotify({
					type: 'warning',
					message: 'Неверный формат файла! Допустимый формат - *.xlsx',
				})
			)
			return
		}

		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })
		const data = new FormData()
		data.append('file', file[0], file[0].name)

		const response = yield fetch(
			`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/uploadForm20?importInto=${importInto}&uncSort=${uncSort}&companyId=${companyId}`,
			{
				method: 'POST',
				body: data,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('access_token')}`,
				},
			}
		)

		if (!response.ok) {
			yield put(
				showNotify({
					type: 'error',
					message: 'Ошибка загрузки файла, попробуйте позже.',
				})
			)
			return
		}

		if (response.headers.get('content-type')?.includes('application/json')) {
			const success = yield response.json()

			if (success.status === 'failure') {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
				store.dispatch(
					showNotify({
						type: 'error',
						message: success.message,
					})
				)
			} else {
				if (Array.isArray(success.done.errors.Error) || Array.isArray(success.done.errors.Notice)) {
					store.dispatch(setDataImportMagazine(success.done.errors, success.done.reportUrl))
					if (success.done.errors.Error.length || success.done.errors.Notice.length)
						store.dispatch(setOpenModalImportMagazine(true))
					store.dispatch(
						showNotify({
							type: 'success',
							message:
								'Файл обработан!' +
								(success.done.errors?.Error?.length
									? ' Количество ошибок: ' + success.done.errors?.Error?.length
									: '') +
								(success.done.errors?.Notice?.length
									? ' Количество предупреждений: ' + success.done.errors?.Notice?.length
									: ''),
						})
					)
				}
			}
		} else {
			// Обрабатываем потоковые данные
			const reader = response.body.getReader()
			const decoder = new TextDecoder('utf-8')
			let buffer = '' // буфер для хранения данных
			let finalJsonBuffer = '' // буфер для данных JSON которые парсим потом

			while (true) {
				const { done, value } = yield reader.read()
				// декодируем фрагмент данных
				buffer += decoder.decode(value, { stream: true })
				let boundary = buffer.indexOf('\n')

				while (boundary !== -1) {
					const chunk = buffer.slice(0, boundary).trim()
					buffer = buffer.slice(boundary + 1) // оставляем остаток в буфере для обработки

					if (chunk.startsWith('data:')) {
						const progressPercent = parseInt(chunk.replace('data:', '').trim())
						if (!isNaN(progressPercent)) {
							store.dispatch(setProgressPrecent(progressPercent))
						}
					} else if (chunk) {
						// собираем данные
						finalJsonBuffer = chunk
					}
					boundary = buffer.indexOf('\n')
				}

				// если поток завершён, проверяем оставшийся буфер
				if (done) {
					if (buffer.trim()) {
						finalJsonBuffer = buffer.trim() // обрабатываем оставшиеся данные в буфере
					}
					if (finalJsonBuffer) {
						try {
							const parsedData = JSON.parse(finalJsonBuffer)
							if (parsedData.status === 'failure') {
								// Обрабатываем ошибку, если в message есть текст ошибки
								store.dispatch(
									showNotify({
										type: 'error',
										message: `Ошибка: ${parsedData.message}`,
									})
								)
							} else if ((parsedData.done && parsedData.done.errors) || parsedData.done.reportUrl) {
								const { errors, reportUrl } = parsedData.done
								if (errors?.Error?.length || errors?.Notice?.length) {
									store.dispatch(setDataImportMagazine(errors, reportUrl))
									store.dispatch(setOpenModalImportMagazine(true))
								}
								store.dispatch(
									showNotify({
										type: 'success',
										message:
											'Файл обработан!' +
											(errors?.Error?.length ? ' Количество ошибок: ' + errors?.Error?.length : '') +
											(errors?.Notice?.length ? ' Количество предупреждений: ' + errors?.Notice?.length : ''),
									})
								)
							}
						} catch (e) {
							console.error('Ошибка парсинга: ', e)
						}
					} else {
						console.error('Ошибка сборки или получения данных')
					}

					yield put(setProgressPrecent(0))
					break
				}
			}
		}

		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
	} catch (error) {
		console.error(error)
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
		store.dispatch(
			showNotify({
				type: 'error',
				message: 'Ошибка обработки файла, попробуйте позже.',
			})
		)
	}
}

function* downloadSvod({ id, name }: { type: typeof UserProjectsType.DOWNLOAD_SVOD; id: ReactText; name: string }) {
	// Сводный файл
	const url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/downloadSvod`
	const { userProjects } = store.getState().filter
	const { sort, projectList } = store.getState().userProjects

	let params = { order: (sort ? '' : '-') + sort.sortName, limit: projectList.length }
	const object = excludeData(userProjects.showFilterState)
	const intermediateValue = {
		...object,
	}
	if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}

	const paramsString = Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&')

	try {
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })

		yield fetch(url + '?' + paramsString, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `Сводный.xlsx`
				link.click()
				link.remove()
			})

		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
	} catch (error) {
		console.log(error)
	}
}

function* uploadSvod({ file }: { type: typeof UserProjectsType.UPLOAD_SVOD; file: any }) {
	try {
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(file[0].type)) {
			yield put(
				showNotify({
					type: 'warning',
					message: 'Неверный формат файла! Допустимый формат - *.xlsx',
				})
			)
			return
		}
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })

		const data = new FormData()
		data.append('file', file[0], file[0].name)

		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/uploadSvod`, {
			method: 'POST',
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(response => response.json())
			.then(success => {
				if (success.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: 'Ошибка загрузки файла, попробуйте позже.',
						})
					)
					store.dispatch(
						showNotify({
							type: 'error',
							message: success.message,
						})
					)
				} else {
					store.dispatch(
						showNotify({
							type: 'success',
							message:
								'Файл успешно загружен!' +
								' Обновлено проектов: ' +
								success.done.updated +
								' Перемещено в архив: ' +
								success.done.archived,
						})
					)
				}
			})
			.catch(() => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})

		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setLoadingStates('', false))
	} catch (error) {
		console.log(error)
	}
}

function* copyProject({
	userProjectId,
	code,
}: {
	type: typeof UserProjectsType.COPY_PROJECT
	userProjectId: ReactText
	code: ReactText
}) {
	try {
		const project: IRequestProject = yield call(
			fetchData,
			`/user-projects`,
			(res: any) => res,
			'POST',
			JSON.stringify({ userProjectId, code })
		)

		if (project.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: `Создан новый проект ${code}`,
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}
function* addVersionProject({
	data,
	versionName,
	uncSort,
	currentUrl,
}: {
	type: typeof UserProjectsType.ADD_NEW_VERSION
	data: IUserProject
	versionName: string
	currentUrl: string
	uncSort?: string
}) {
	const copy: IUserProject = uncSort
		? Object.assign({}, data, { version: versionName, unc_sort: uncSort, __projectId: data.id })
		: Object.assign({}, data, { version: versionName })

	delete copy.sumUncCost
	delete copy.userId
	delete copy.id
	delete copy.sumUncCostWithNds
	delete copy.totalInvestmentUncOffer
	delete copy.t7Id
	delete copy.t7Version
	delete copy.versions
	delete copy.author

	try {
		const project: IRequestProject = yield call(
			fetchData,
			`/user-projects`,
			(res: any) => res,
			'POST',
			JSON.stringify(copy)
		)
		if (project.status === 'success' || project) {
			if (project.alert) {
				const alert = project.alert
				yield put(
					showNotify({
						type: alert.type,
						message: alert.message,
					})
				)
			} else {
				yield put(
					showNotify({
						type: 'success',
						message: `Создана версия расчета ${versionName}`,
					})
				)
			}
			yield put(push(`/${currentUrl}/project/${project.userProject.id}/parameters`))
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага для download шаблона ненормированных затрат
function* getTplUnnormalizedСosts({
	id,
}: {
	type: typeof UserProjectsType.GET_TEMPLATE_UNNORMOLIZED_COSTS
	id: ReactText
}) {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7-tpl`, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `Шаблон ненормируемых затрат.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

//Сага для download сформированного файла ненормированных затрат
function* getUnnormalizedСosts({ id }: { type: typeof UserProjectsType.GET_UNNORMOLIZED_COSTS; id: ReactText }) {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7`, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `Шаблон ненормируемых затрат.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

//Сага для upload заполненного шаблона ненормированных затрат
function* uploaderUnnormalizedСosts({
	file,
	id,
}: {
	type: typeof UserProjectsType.UPLOAD_UNNORMOLIZED_COSTS
	file: any
	id: ReactText
}) {
	try {
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(file[0].type)) {
			yield put(
				showNotify({
					type: 'warning',
					message: 'Неверный формат файла! Допустимый формат - *.xlsx',
				})
			)
			return
		}

		const data = new FormData()
		data.append('file', file[0], file[0].name)

		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7`, {
			method: 'POST',
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(response => response.json())
			.then(success => {
				if (success.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: 'Ошибка загрузки файла, попробуйте позже.',
						})
					)
				} else {
					store.dispatch(
						showNotify({
							type: 'success',
							message: 'Файл успешно загружен!',
						})
					)
				}
			})
			.catch(() => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})
		yield put({ type: 'FETCH_USER_PROJECT', id: id })
		yield put({ type: 'GET_T7_DATA', id: id })
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы T7 проекта
function* getT7Data({ id, version }: { type: typeof UserProjectsType.GET_T7_DATA; id: string; version: number }) {
	try {
		yield put(loadingT7Data(true))

		if (version == null) version = 2

		const t7Data: IT7Data = yield call(
			fetchData,
			`/user-projects/${id}/T7Data?version=${version}`,
			(res: any) => res.t7
		)
		if (t7Data) {
			yield put({ type: 'SET_T7_DATA', t7Data })
			yield put(loadingT7Data(false))
		} else {
			yield put(loadingT7Data(false))
		}
	} catch (error) {
		yield put(loadingT7Data(false))
		console.log(error)
	}
}

//Сага сохранения таблицы ненормируемых расходов
function* saveT7Data({
	t7Data,
	userProjectId,
	version,
}: {
	type: typeof UserProjectsType.SAVE_T7_DATA
	t7Data: IT7Data
	userProjectId: ReactText
	version: number
}) {
	try {
		const project = yield call(
			fetchData,
			`/user-projects/${userProjectId}/updateT7?version=${version}`,
			(res: any) => res,
			'PATCH',
			JSON.stringify(t7Data)
		)

		const message =
			version == 1
				? `. Если ненормируемые затраты ранее заполнены по вкладке 20.2 формы 20, то они остаются приоритетными`
				: ``

		if (project.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: `Таблица ненормированных затрат обновлена` + message,
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага удаления файла ненормированных затрат
function* deleteUnnormalizedСosts({ id }: { type: typeof UserProjectsType.DELETE_UNNORMOLIZED_COSTS; id: ReactText }) {
	try {
		const res: IRequestProject = yield call(fetchData, `/user-projects/${id}/t7`, (res: any) => res, 'DELETE')

		if (res.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: 'Файл успешно удален!',
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}

function* updateCalc(data: any) {
	try {
		const query = data.params
		let params = { order: (query.order ? '' : '-') + query.sortName, limit: limit }

		const { userProjects } = store.getState().filter
		const object = excludeData(userProjects.showFilterState)

		const intermediateValue = {
			...object,
		}

		if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: true })

		const response = yield fetch(
			`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/computeAll?${QueryString.stringify(params, {
				arrayFormat: 'comma',
			})}`,
			{
				method: 'POST',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('access_token')}`,
				},
			}
		)

		if (!response.ok) {
			yield put(
				showNotify({
					type: 'error',
					message: 'Ошибка в обработке файла, попробуйте позже.',
				})
			)
			return
		}

		const reader = response.body.getReader()
		const decoder = new TextDecoder('utf-8')
		let buffer = ''
		let finalJsonBuffer = ''

		while (true) {
			const { done, value } = yield reader.read()

			if (done) {
				break
			}

			buffer += decoder.decode(value, { stream: true })

			let boundary = buffer.indexOf('\n')

			while (boundary !== -1) {
				const chunk = buffer.slice(0, boundary).trim()
				buffer = buffer.slice(boundary + 1)

				if (chunk.startsWith('data:')) {
					const progressPercent = parseInt(chunk.replace('data:', '').trim())
					if (!isNaN(progressPercent)) {
						yield put(setProgressPrecent(progressPercent))
					}
				} else if (chunk) {
					finalJsonBuffer = chunk
				}
				boundary = buffer.indexOf('\n')
			}
		}

		if (buffer.trim()) {
			finalJsonBuffer = buffer.trim()
		}

		if (finalJsonBuffer) {
			try {
				const parsedData = JSON.parse(finalJsonBuffer)

				if (parsedData.status === 'success') {
					yield put(
						showNotify({
							type: 'success',
							message: 'Отобранные расчеты успешно обновлены!',
						})
					)
				} else {
					yield put(
						showNotify({
							type: 'error',
							message: `Ошибка: ${parsedData.message}`,
						})
					)
				}
			} catch (e) {
				console.error('Ошибка парсинга JSON: ', e)
				yield put(
					showNotify({
						type: 'error',
						message: 'Ошибка обработки данных, попробуйте позже.',
					})
				)
			}
		}

		yield put(setLoadingStates('', false))
	} catch (error) {
		console.error('Произошла ошибка: ', error)
		yield put(
			showNotify({
				type: 'error',
				message: 'Произошла ошибка при обработке запроса, попробуйте позже.',
			})
		)
	} finally {
		yield put({ type: UserProjectsType.SHOW_LOADER, showLoader: false })
		yield put(setProgressPrecent(0))
		yield put(setLoadingStates('', false))
	}
}

function* sortUserProjects({
	order,
	sortName,
}: {
	type: typeof UserProjectsType.SORT_USER_PROJECTS
	sortName: string
	order: boolean
	archive: any
}) {
	try {
		yield put(loadingUserProjects(true))
		yield put(setUserProjects([], 0, 0))
		let params = { order: (order ? '' : '-') + sortName, limit: limit }

		const { userProjects } = store.getState().filter
		const object = excludeData(userProjects.showFilterState)

		const intermediateValue = {
			...object,
		}

		if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		const res = yield call(userProjectsApi, params)

		if (res && res.status === 'success') {
			yield put({
				type: 'SET_USER_PROJECTS',
				projects: res.userProjects,
				userProjectsCnt: res.userProjectsCnt,
				totalInvestmentUncOfferSum: Number(res.totalInvestmentUncOfferSum),
			})
			yield put(setExportable(res.exportable))
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(loadingUserProjects(false))
	}
}

function* appendUserProjects({
	projectsCount,
}: {
	type: typeof UserProjectsType.APPEND_USER_PROJECTS
	projectsCount: number
}) {
	try {
		yield put(loadingUserProjects(true))
		const { sort, allowMoreUserProjects } = store.getState().userProjects

		if (!allowMoreUserProjects) return

		let params = { order: (sort.order ? '' : '-') + sort.sortName, limit: limit, offset: projectsCount * limit }

		const { userProjects } = store.getState().filter
		const object = excludeData(userProjects.showFilterState)

		const intermediateValue = {
			...object,
		}

		if (userProjects.showFilterState) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		const res = yield call(userProjectsApi, params)

		if (res && res.status === 'success') {
			const projects = res.userProjects

			if (res.userProjects.length) {
				yield put(addUserProjects(projects))
				yield put(setUserProjectsPaginationCount(projectsCount))
			} else {
				yield put(setAllowMoreUserProjects(false))
			}

			if (!(res.userProjects.length || res.userProjects.length > limit)) {
				yield put(setAllowMoreUserProjects(false))
				yield put(setUserProjectsPaginationCount(0))
			}
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(loadingUserProjects(false))
	}
}

// function createEventSourceChannel(userId: string | number, processName: string) {
// 	function getRandomInt(min: number, max: number) {
// 		min = Math.ceil(min)
// 		max = Math.floor(max)
// 		return Math.floor(Math.random() * (max - min + 1)) + min
// 	}
// 	return eventChannel(emitter => {
// 		const evtSource = new EventSource(
// 			`https://${process.env.REACT_APP_ENV}/scripts/sse.php?process=${userId}${processName}&${getRandomInt(1, 999)}`
// 		)

// 		evtSource.onmessage = (event) => {
// 			const percent = parseFloat(event.data);

// 			if (!isNaN(percent)) {
// 			  emitter({ percent });
// 			} else {
// 			  console.error('Invalid percent received:', event.data);
// 			}
// 		  };

// 		  evtSource.onerror = (error) => {
// 			emitter({ error: new Error('SSE connection failed') });
// 			evtSource.close();
// 		  };

// 		  evtSource.addEventListener('complete', () => {
// 			emitter({ complete: true });
// 			evtSource.close();
// 		  });

// 		return () => {
// 			evtSource.close()
// 		}
// 	})
// }

// function* handleSSERequest({
// 	processName,
// 	userId,
// }: {
// 	type: typeof UserProjectsType.GET_PROGRESS_UPDATE
// 	processName: string
// 	userId: string | number
// }) {
// 	yield delay(5000)
// 	const channel = yield call(createEventSourceChannel, userId, processName)

// 	try {
// 		while (true) {
// 			const { percent, error, complete } = yield take(channel)
// 			if (error) {
// 				console.log(error.message)
// 				break
// 			}

// 			if (complete) {
// 				break
// 			}

// 			if (percent !== undefined) {
// 				yield put(setProgressPrecent(percent))
// 			}
// 		}
// 	} catch (error) {
// 		console.log(error.message);

// 	} finally {
// 		channel.close()
// 	}
// }

export default function* watchUserProject() {
	yield takeEvery(UserProjectsType.DELETE_UNNORMOLIZED_COSTS, deleteUnnormalizedСosts)
	yield takeEvery(UserProjectsType.UPLOAD_UNNORMOLIZED_COSTS, uploaderUnnormalizedСosts)
	yield takeEvery(UserProjectsType.APPEND_USER_PROJECTS, appendUserProjects)
	yield takeEvery(UserProjectsType.GET_UNNORMOLIZED_COSTS, getUnnormalizedСosts)
	yield takeEvery(UserProjectsType.SORT_USER_PROJECTS, sortUserProjects)
	yield takeEvery(UserProjectsType.COPY_PROJECT, copyProject)
	yield takeEvery(UserProjectsType.ADD_NEW_VERSION, addVersionProject)
	yield takeEvery(UserProjectsType.GET_TEMPLATE_UNNORMOLIZED_COSTS, getTplUnnormalizedСosts)
	yield takeEvery(UserProjectsType.DOWNLOAD_F20, downloadForm20)
	yield takeEvery(UserProjectsType.DOWNLOAD_F20_ASYNC, downloadForm20async)
	yield takeEvery(UserProjectsType.DOWNLOAD_CHECK_WARNS, downloadCheckWarns)
	yield takeEvery(UserProjectsType.CHECK_F20_LINK, checkF20link)
	yield takeEvery(UserProjectsType.MASS_CHECK, massСheck)
	yield takeEvery(UserProjectsType.UPLOAD_F20, uploadForm20)
	yield takeEvery(UserProjectsType.DOWNLOAD_F2, downloadForm2)
	yield takeEvery(UserProjectsType.UPLOAD_F2, uploadForm2)
	yield takeEvery(UserProjectsType.DOWNLOAD_SVOD, downloadSvod)
	yield takeEvery(UserProjectsType.UPLOAD_SVOD, uploadSvod)
	yield takeEvery(UserProjectsType.UPLOADER_RULES_CHECK, uploaderRulesCheck)
	yield takeEvery(UserProjectsType.DOWNLOAD_RULES_CHECK, downloadRulesCheck)
	yield takeEvery(UserProjectsType.SET_DOWNLOAD_IMPORT_DATA, downloadImportLog)
	yield takeEvery(UserProjectsType.GET_CHECKED_ERRORS, getCheckedErrors)
	yield takeEvery(UserProjectsType.AUTO_ADD_UNC, autoAddUnc)
	yield takeLatest(UserProjectsType.UPDATE_DEFLATORS, updateDeflators)
	yield takeLatest(UserProjectsType.UPDATE_DEFLATORS_VALUE, updateDeflatorsValue)
	yield takeLatest(UserProjectsType.GET_DEFLATORS, getAllDeflators)
	yield takeEvery(UserProjectsType.SELECT_VOLTAGE, selectVoltage)
	yield takeEvery(UserProjectsType.GET_UNC_CELLS, handleUncCells)
	yield takeEvery(UserProjectsType.CHANGE_UNC_VOLTAGE, changeUncVoltage)
	yield takeEvery(UserProjectsType.CHANGE_UNC_REGION, changeUncRegion)
	yield takeEvery(UserProjectsType.CHANGE_UNC_COUNTER, changeUncCounter)
	yield takeEvery(UserProjectsType.CHANGE_UNC_COMMENT, changeUncComment)
	yield takeEvery(UserProjectsType.CHANGE_UNC_CF_ARCHITECTURE, changeUncCfArchitecture)
	yield takeLatest(UserProjectsType.FETCH_UNC_TABLE_DATA, fetchUncTableData)
	yield takeLatest(UserProjectsType.FETCH_UNCS, fetchUncs)
	yield takeLatest(UserProjectsType.GET_NON_STANDARDIZED_COSTS, fetchNonStandardizedCosts)
	yield takeLatest(UserProjectsType.GET_T7_DATA, getT7Data)
	// yield takeLatest(UserProjectsType.GET_USER_PROJECTS, getMyProjects)
	// 	yield takeEvery(UserProjectsType.APPEND_UNC_SEARCH, appendPricesSearch)
	// 	yield takeLatest(UserProjectsType.FETCH_MINI_TABLE, fetchMiniTable)
	// yield takeLatest(UserProjectsType.FETCH_COST_TABLE, fetchCostTable)
	yield takeLatest(UserProjectsType.CHANGE_FORM_TWENTY_NAME, changeFormTwentyName)
	yield takeLatest(UserProjectsType.CHANGE_REGION_FACTOR, changeRegionFactor)
	yield takeEvery(UserProjectsType.DELETE_UNC, deleteUnc)
	yield takeEvery(UserProjectsType.GET_ANALOG_UNC, genAnalogUnc)

	yield takeEvery(UserProjectsType.GET_ASSIST_STATUS, fetchAssistStatus)
	yield takeEvery(UserProjectsType.UPDATE_ASSIST_STATUS, changeAssistStatus)
	// yield takeEvery(UserProjectsType.GET_PROGRESS_UPDATE, handleSSERequest)

	// yield takeLatest(UserProjectsType.FETCH_MINI_TABLE, fetchMiniTable)
	yield takeEvery(UserProjectsType.CREATE_SPECIAL_UNC, createSpecialUnc)
	yield takeEvery(UserProjectsType.CREATE_UNCS_B1, createUncsB1)
	yield takeEvery(UserProjectsType.CREATE_UNC_B1, createUncB1)
	yield takeLatest(UserProjectsType.ADD_UNC, addUnc)
	yield takeLatest(UserProjectsType.REPLACE_UNC, replaceUnc)
	yield takeLatest(UserProjectsType.UNC_POSITION_EDIT, uncEdit)
	yield takeLatest(UserProjectsType.SAVE_USER_PROJECT, saveUserProject)
	yield takeLatest(UserProjectsType.UPDATE_STATUS_USER_PROJECT, updateStatusUserProject)
	yield takeLatest(UserProjectsType.FETCH_USER_PROJECT, fetchUserProject)
	yield takeLatest(UserProjectsType.DELETE_USER_PROJECT, deleteUserProject)
	yield takeLatest(UserProjectsType.SAVE_T7_DATA, saveT7Data)
	yield takeLatest(UserProjectsType.UPDATE_CALC, updateCalc)
}
